<template>
  <div class="adjustment">
    <!-- 理算 -->
    <el-form label-position="top"
      :model="form"
      ref="claimFrom"
      :rules="rulesClaimFrom">
      <AssociationScroll :showTabs="false">
        <AssociationScrollView title="赔付金额"
          name="0">
          <div class="form-content">
            <el-form-item label="定损金额合计"
              v-if="auth('lossAmount','v')">
              <el-input placeholder="请输入"
                disabled
                v-model="form.lossAmount"></el-input>
            </el-form-item>
            <el-form-item label="免赔金额"
              v-if="auth('deductionAmount','v')">
              <el-input placeholder="请输入"
                disabled
                v-model="form.deductionAmount"></el-input>
            </el-form-item>
            <el-form-item label="投保比例"
              v-if="auth('insureProportion','v')">
              <el-input placeholder="请输入"
                disabled
                v-model="form.insureProportion"></el-input>
            </el-form-item>
            <el-form-item label="责任比例"
              v-if="auth('dutyScale','v')">
              <el-input placeholder="请输入"
                disabled
                v-model="form.dutyScale"></el-input>
            </el-form-item>
            <el-form-item label="残值合计"
              v-if="auth('salvageAmountTotal','v')">
              <el-input placeholder="请输入"
                disabled
                v-model="form.salvageAmountTotal"></el-input>
            </el-form-item>
            <el-form-item label="应赔金额"
              v-if="auth('compensateAmount','v')">
              <el-input placeholder="请输入"
                disabled
                v-model="form.compensateAmount"></el-input>
            </el-form-item>
          </div>
          <el-switch class="switch"
            v-model="form.hasPrepay"
            :disabled="!edit"
            v-if="detail.lastStatus === 10&&auth('hasPrepay','v')"
            inactive-text="是否预赔付"></el-switch>
        </AssociationScrollView>
        <AssociationScrollView title="支付详情"
          name="1">
          <el-table :data="form.payeeList"
            border
            style="margin: 0;">
            <el-table-column label="序号"
              type="index"
              width="50"></el-table-column>
            <el-table-column label="收款方"
              prop="payeeInfo"
              v-if="auth('payee','e')"
              width="180">
              <template slot-scope="{row, $index}">
                <el-form-item :prop="'payeeList.' + $index + '.payee'"
                  :ref="'payeeList.' + $index + '.payee'"
                  :rules="[{ required: true, message: '请选择收款方' }]">
                  <!-- <el-input placeholder="请输入"
                  disabled
                v-model="item.payee"></el-input>-->
                  <el-select v-model="row.payeeInfo"
                    :disabled="!row.reopenCount&&isReOpen"
                    placeholder="请选择"
                    value-key="id"
                    style="width: 100%"
                    v-if="(detail.lastStatus === 9 && edit) || (row.reopenCount == detail.reopenCount && isReOpen && edit && detail.adjustmentProcessStatus !== 3)"
                    @change="handlePayeeSelect($event,row)">
                    <el-option v-for="itm in payeeDetail.insClaimsInformationList"
                      :key="itm.id"
                      :label="itm.name?itm.name:itm.corporateName"
                      :value="itm">
                      <span>{{itm.name?itm.name:itm.corporateName}}</span>
                    </el-option>
                  </el-select>
                  <DescriptionsItem v-else
                    v-model="row.payee"></DescriptionsItem>
                </el-form-item>
              </template>
            </el-table-column>
            <el-table-column label="收款方账号"
              prop="payeeBankNo"
              v-if="auth('payee','v')"
              width="180">
              <template slot-scope="{row}">
                <el-form-item label>
                  <DescriptionsItem v-model="row.payeeBankNo"></DescriptionsItem>
                </el-form-item>
              </template>
            </el-table-column>
            <el-table-column label="费用项"
              width="160"
              prop="reopenCount">
              <template slot-scope="{row, $index}">
                <el-form-item v-if="auth('costItem','e')"
                  :prop="'payeeList.' + $index + '.costItem'"
                  :ref="'payeeList.' + $index + '.costItem'"
                  :rules="[{ required: true, message: '不能为空' }]">
                  <el-select :disabled="!row.reopenCount&&isReOpen"
                    v-if="(detail.lastStatus === 9 && edit) || (isReOpen && edit && detail.adjustmentProcessStatus !== 3)"
                    v-model="row.costItemVal"
                    filterable @change="handleChangeSelect($event, form.payeeList[$index], claimsPaymentItemList, 'costItem', 'dictValue')"
                    placeholder="请选择">
                    <el-option v-for="(dictItem) in claimsPaymentItemList"
                      :key="dictItem.dictKey"
                      :label="dictItem.dictValue"
                      :value="dictItem.dictKey"></el-option>
                  </el-select>
                  <DescriptionsItem v-else
                    v-model="row.costItem"></DescriptionsItem>
                </el-form-item>
              </template>
            </el-table-column>
            <el-table-column label="金额"
              prop="payAmount"
              width="180">
              <template slot-scope="{row, $index}">
                <el-form-item v-if="auth('payAmount','e')"
                  :prop="'payeeList.' + $index + '.payAmount'"
                  :ref="'payeeList.' + $index + '.payAmount'"
                  :rules="[{ required: true, message: '不能为空' }]">
                  <!-- <DescriptionsItem v-if="edit  && item.reopenCount !== detail.reopenCount"
                     v-model="item.payAmount"></DescriptionsItem>-->
                  <el-input placeholder="请输入"
                    :disabled="!row.reopenCount&&isReOpen"
                    v-number-input.float="2"
                    v-if="(detail.lastStatus === 9 && edit) || (row.reopenCount == detail.reopenCount && isReOpen && edit && detail.adjustmentProcessStatus !== 3)"
                    v-model="row.payAmount"></el-input>
                  <DescriptionsItem v-else
                    v-model="row.payAmount"></DescriptionsItem>
                </el-form-item>
              </template>
            </el-table-column>
            <el-table-column label="备注"
              prop="remark">
              <template slot-scope="{row, $index}">
                <el-form-item v-if="auth('remark','e')"
                  :prop="'payeeList.' + $index + '.remark'"
                  :ref="'payeeList.' + $index + '.remark'">
                  <el-input placeholder="请输入"
                    :disabled="!row.reopenCount&&isReOpen"
                    v-if="(detail.lastStatus === 9 && edit) || (row.reopenCount == detail.reopenCount && isReOpen && edit && detail.adjustmentProcessStatus !== 3)"
                    v-model="row.remark"></el-input>
                  <DescriptionsItem v-else
                    v-model="row.remark"></DescriptionsItem>
                </el-form-item>
              </template>
            </el-table-column>
            <el-table-column label="支付时间"
              v-if="!edit && (detail.lastStatus == 2 || detail.lastStatus == 4)"
              prop="payTime">
              <template slot-scope="{row, $index}">
                <!-- v-if="((item.reopenCount !== detail.reopenCount) || (item.id && item.reopenCount === detail.reopenCount && (detail.adjustmentProcessStatus === 3 || detail.adjustmentProcessStatus === 2)))&&auth('payTime','e')" -->
                <el-form-item :prop="'payeeList.' + $index + '.payTime'">
                  <el-date-picker v-model="row.payTime"
                    :disabled="!row.reopenCount&&isReOpen"
                    type="datetime"
                    v-if="edit"
                    value-format="yyyy-MM-dd hh:mm:ss"
                    placeholder="选择日期时间"></el-date-picker>
                  <DescriptionsItem v-else
                    v-model="row.payTime"></DescriptionsItem>
                </el-form-item>
              </template>
            </el-table-column>
            <el-table-column label="通知单号"
              v-if="!edit && (detail.lastStatus == 2 || detail.lastStatus == 4)"
              prop="noticeNo">
              <template slot-scope="{row, $index}">
                <el-form-item :prop="'payeeList.' + $index + '.noticeNo'">
                  <el-input placeholder="请输入"
                    v-if="edit"
                    :disabled="!row.reopenCount&&isReOpen"
                    v-model="row.noticeNo"></el-input>
                  <DescriptionsItem v-else
                    v-model="row.noticeNo"></DescriptionsItem>
                </el-form-item>
              </template>
            </el-table-column>
            <el-table-column label="流水号"
              v-if="!edit && (detail.lastStatus == 2 || detail.lastStatus == 4)"
              prop="tradeCode">
              <template slot-scope="{row, $index}">
                <el-form-item :prop="'payeeList.' + $index + '.tradeCode'">
                  <el-input placeholder="请输入"
                    v-if="edit"
                    :disabled="!row.reopenCount&&isReOpen"
                    v-model="row.tradeCode"></el-input>
                  <DescriptionsItem v-else
                    v-model="row.tradeCode"></DescriptionsItem>
                </el-form-item>
              </template>
            </el-table-column>
            <el-table-column label="操作"
              v-if="edit"
              width="160"
              prop="ooo">
              <template slot-scope="{row, $index}">
                <el-button type="success"
                  style="margin-top: 20px"
                  @click="addPayee"
                  v-if="edit && detail.lastStatus !== 10  && detail.adjustmentProcessStatus !== 3 && $index == form.payeeList.length -1">添加</el-button>
                <el-button type="danger"
                  v-if=" ($index && edit && !isReOpen && detail.lastStatus !== 10) || (row.reopenCount === detail.reopenCount && $index && edit && !row.isReAdd && detail.adjustmentProcessStatus !== 3)"
                  @click="delList($index)">删除</el-button>
              </template>
            </el-table-column>
          </el-table>
          <!-- <el-button type="success"
            style="margin-top: 20px"
            @click="addPayee"
            v-if="edit && detail.lastStatus !== 10  && detail.adjustmentProcessStatus !== 3">添加</el-button> -->
          <div class="totalMoney"
            v-if="totalMoney > 0">赔付总金额：{{totalMoney}}</div>
          <el-form-item v-if="auth('remark','e')"
            label="备注"
            prop="remark"
            ref="remark"
            style="margin-top:10px">
            <el-input placeholder="请输入"
              :disabled="isReOpen"
              type="textarea"
              v-if="edit"
              v-model="form.remark"
              :rows="4"></el-input>
            <DescriptionsItem v-else
              v-model="form.remark"></DescriptionsItem>
          </el-form-item>
        </AssociationScrollView>
        <AssociationScrollView title="单据影像资料"
          v-show="detail.lastStatus == 2 || detail.lastStatus == 4"
          name="2">
          <PicUpload v-model="picList"
            :disabled="true"
            class="pic-upload"></PicUpload>
        </AssociationScrollView>
        <AssociationScrollView class="approval"
          title="理算审核"
          v-show="(detail.lastStatus >= 9 || detail.lastStatus == 3 || detail.lastStatus == 4 || detail.lastStatus == 2) && detail.adjustmentProcessStatus !== -1"
          name="3">
          <auditProcess :auditList="auditList"
            :negotiationList="negotiationList"></auditProcess>
        </AssociationScrollView>
        <AssociationScrollView class="approval"
          title="结算数据"
          v-show="detail.lastStatus == 3"
          name="4">
          <el-row :gutter="10">
            <el-col v-if="auth('reportCaseTime','v')"
              :xs="24"
              :sm="12"
              :md="12"
              :lg="8"
              :xl="6">
              <div class="detail-text">报案时间：{{ closeData.reportCaseTime }}</div>
            </el-col>
            <el-col v-if="auth('closeCaseTime','v')"
              :xs="24"
              :sm="12"
              :md="12"
              :lg="8"
              :xl="6">
              <div class="detail-text">结案时效：{{ closeData.closeCaseTime }}</div>
            </el-col>
            <el-col v-if="auth('closeDurationTime','v')"
              :xs="24"
              :sm="12"
              :md="12"
              :lg="8"
              :xl="6">
              <div class="detail-text">报案时间：{{ closeData.closeDurationTime }}</div>
            </el-col>
            <el-col v-if="auth('estimateLoss','v')"
              :xs="24"
              :sm="12"
              :md="12"
              :lg="8"
              :xl="6">
              <div class="detail-text">预估损失金额：{{ closeData.estimateLoss }}</div>
            </el-col>
            <el-col v-if="auth('realCompensationAmount','v')"
              :xs="24"
              :sm="12"
              :md="12"
              :lg="8"
              :xl="6">
              <div class="detail-text">实际赔付金额：{{ closeData.realCompensationAmount }}</div>
            </el-col>
            <el-col v-if="auth('accuracy','v')"
              :xs="24"
              :sm="12"
              :md="12"
              :lg="8"
              :xl="6">
              <div class="detail-text">准确率：{{ closeData.accuracy || '0%' }}</div>
            </el-col>
            <el-col v-if="auth('caseFollower','v')"
              :xs="24"
              :sm="12"
              :md="12"
              :lg="8"
              :xl="6">
              <div class="detail-text">客户经理：{{ closeData.caseFollower }}</div>
            </el-col>
            <el-col v-if="auth('regionalManager','v')"
              :xs="24"
              :sm="12"
              :md="12"
              :lg="8"
              :xl="6">
              <div class="detail-text">区域经理：{{ closeData.regionalManager }}</div>
            </el-col>
          </el-row>
          <div v-if="auth('closeRemark','v')">备注</div>
          <el-input v-if="auth('closeRemark','v')"
            type="textarea"
            :rows="5"
            disabled
            placeholder="请输入内容"
            v-model="closeData.closeRemark"></el-input>
        </AssociationScrollView>
        <AssociationScrollView class="approval"
          title="重开信息"
          v-show="(detail.lastStatus == 3 || detail.lastStatus == 4 || (detail.lastStatus == 2 && detail.reopenProcessStatus == 2))"
          name="3">
          <auditProcess :auditList="closeList"
            :negotiationList="negotiationList"
            :closeReason="closeReason"
            :isTitle="false"></auditProcess>
        </AssociationScrollView>
      </AssociationScroll>
    </el-form>
    <!-- <div class="operating-box">
      <el-button size="mini">返回</el-button>
      <el-button size="mini"
        type="warning">提交</el-button>
      <el-button size="mini"
        type="danger">中止流程</el-button>
    </div>-->
  </div>
</template>

<script>
import dayjs from "dayjs";
import AssociationScroll from "@/components/AssociationScroll/AssociationScroll.vue";
import AssociationScrollView from "@/components/AssociationScroll/AssociationScrollView.vue";
import PicUpload from "../PicUpload.vue";
import DescriptionsItem from "../items/DescriptionsItem.vue";
import auditProcess from "../auditProcess.vue";
import { getClaimsDataSheetDetail, claimLossDetail, getAdjustmentDetail, saveAdjustment, getAuditRecord, insClaimsCaseCloseList, pageNegotiation } from '@/api/claimData.js';
import { hasPerms, dataPermissions } from "@/utils/auth";
import { dictionaryBatch } from "@/api/policy";
export default {
  name: "LiquidatedDamages",
  components: {
    AssociationScroll,
    AssociationScrollView,
    PicUpload,
    DescriptionsItem,
    auditProcess
  },
  props: {
    detail: {
      type: Object,
      default: () => { },
    },
    id: {
      type: [String, Number],
      default: "",
    },
    edit: {
      type: Boolean,
      default: false,
    },
    processStatus: {
      type: Object,
      default: () => { },
    },
    auditData: {
      type: Object,
      default: () => { },
    },
    isReOpen: {
      type: Boolean,
      default: false,
    }
  },
  data() {
    return {
      auditList: [],
      closeList: [],
      negotiationList: [],
      picList: [],
      form: {
        lossAmount: '',
        deductionAmount: '',
        insureProportion: '',
        dutyScale: '',
        salvageAmountTotal: '',
        compensateAmount: '',
        remark: '',
      //   attachmentType: 'DJYX',
        hasPrepay: false,
        payeeList: [{ payee: '', payeeBankNo: '', payAmount: '', costItem: '', costItemVal: '', reopenCount: 0 }],
      },
      payeeDetail: {},
      damagesDetail: {},
      oldData: {},
      closeData: {},
      closeReason: '',
      oldData: {},
      rulesClaimFrom: {},
      claimsPaymentItemList: []
    };
  },
  watch: {
    auditData() {
      this.initData();
    },
    edit: {
      handler(v) {
         if (!v) {
            this.initData();
         }else {
            // this.form.payeeList.length ?  this.isReOpen ? this.form.payeeList.push({ payee: '', payeeBankNo: '', payAmount: '', costItem: '', costItemVal: '', reopenCount: 0 }) : '' : this.form.payeeList = [{ payee: '', payeeBankNo: '', payAmount: '', costItem: '', costItemVal: '', reopenCount: 0 }];
            this.form.payeeList.length ?  this.isReOpen ? '' : '' : this.form.payeeList = [{ payee: '', payeeBankNo: '', payAmount: '', costItem: '', costItemVal: '', reopenCount: 0 }];
         }
      },
      immediate: false
    }
  },
  computed: {
    totalMoney() {
      let amount = 0;
      this.form.payeeList.forEach(element => {
         //  && element.costItem == '赔款' 
        if (element.payee !== '广州美捷保险公估有限公司' && (element.costItem != '差旅费' && element.costItem != '公估费' && element.costItem != '其他费用')) {
          amount += Number(element.payAmount);
        }
      });
      return amount.toFixed(2);
    }
  },
  created() {
    this.initData();
    this.initDictionary();
    if (this.detail.lastStatus == 3) {
      this.getCloseList();
    }
  },
  methods: {
    handleWithdraw() {
      return {
        audit: true,
        processId: this.auditData.processId
      };
    },
    initDictionary() {
      // 设备类型  dangerType; 投保门店applicantStore; 设备品牌（vehicleBrand）、设备类型（vehicleType）
      dictionaryBatch({
        codes:
          "claimsPaymentItem",
      }).then((res) => {
        if (res && res.data) {
          const {
            claimsPaymentItem
          } = res.data;
          this.claimsPaymentItemList = claimsPaymentItem || [];
        }
      });
    },
    async getAudit(approvalType) {
      //获取审核记录
      let auditResult = await getAuditRecord({
        approvalType,
        caseId: this.id,
      });
      //获取协商记录
      let negotiation = await pageNegotiation({
        approvalType,
        caseId: this.id,
        size: 999,
        //   processId: auditResult.data.processId,
        current: 1,
      });
      let auditData = auditResult && auditResult.data && auditResult.data.nodes && auditResult.data.nodes.length ? auditResult.data.nodes : [];

      if (approvalType != 3) {
        this.closeReason = auditResult && auditResult.data && auditResult.data.requestContent ? auditResult.data.requestContent : '';
      }
      if (auditData.length) {
        let auditList = auditData.map((node) => {
          return {
            name: node.approverName,
            type: node.nodeStatusDesc,
            time: node.approveTime ? dayjs(node.approveTime).format("YYYY-MM-DD HH:mm:ss") : '',
            comment: node.nodeOpinion,
          };
        });
        if (approvalType == 3) {
          this.auditList = JSON.parse(JSON.stringify(auditList));
        } else {
          this.closeList = JSON.parse(JSON.stringify(auditList));
        }
      } else {
        this.auditList = [
          {
            // name: this.detail.caseFollower,
            name: this.$store.state.userInfo.name,
            type: "发起审核",
            time: dayjs().format("YYYY-MM-DD HH:mm:ss"),
            comment: "",
          },
          //  {
          //    name: this.detail.adjustmentAssistant,
          //    type: this.processStatus[this.detail.subjectLossProcessStatus],
          //    time: "",
          //    comment: "",
          //  },
        ];
      }
      if (negotiation.data && negotiation.data.records) {
        this.negotiationList = negotiation.data.records;
      }
    },
    delList(index) {
      this.form.payeeList.splice(index, 1);
    },
    handlePayeeSelect(item, info) {
      info.payeeBankNo = item.bankNumber;
      info.payee = item.name ? item.name : item.corporateName;
    },
    getDetail() {
      getClaimsDataSheetDetail({ caseId: this.id }).then(res => {
        res.data.insClaimsInformationList = [...res.data.insClaimsInformationList, ...res.data.insClaimsAssessorList];
        this.payeeDetail = res.data;
      });
      claimLossDetail({ caseId: this.id }).then(res => {
        this.damagesDetail = res.data;
        let temp = 0;

        res.data && res.data.itemList ? res.data.itemList.forEach(element => {
          console.log(temp);
          temp += element.amount;
        }) : '';
        this.form.lossAmount = temp;
        this.form.deductionAmount = res.data.totalDeductible;
        this.form.insureProportion = res.data.insureRate;
        this.form.dutyScale = res.data.caseLiabilityRate;
        this.form.salvageAmountTotal = res.data.salvageSum;
        this.form.compensateAmount = res.data.amountPayable;
        this.form.hasPrepay = res.data.hasPrepay;
      });
    },
    addPayee() {
      this.form.payeeList.push({ isReAdd: false, payee: '', payeeBankNo: '', payAmount: '', costItem: '', costItemVal: '', reopenCount: this.isReOpen ? this.detail.reopenCount : 0 });
    },
    handleSave() {

      let data = {
        id: this.curId,
        caseId: this.id,
        claimsPayments: this.form.payeeList,
      //   attachments: { "DJYX": this.picList }
      };
      data = { ...data, ...this.form };
      this.$refs.claimFrom.validate((valid, validObj) => {
        if (valid) {
          if (this.totalMoney > this.form.compensateAmount) {
            this.$message.error('支付总金额已超过定损金额, 请核验支付信息');
            return reject("校验未通过");
          }
          saveAdjustment(data).then(res => {
            if (res) {
              this.$emit("Submit");
            }
          });
        } else {
          //  this.$message.error('校验未通过');
          let messageList = [];
          // this.$message.error('校验未通过');
          for (const key in validObj) {
            messageList.push(validObj[key][0]);
          }
          if (messageList.length) {
            this.$message.error(messageList[0].message);
            this.$refs[messageList[0].field].$el.scrollIntoView();
          } else {
            reject("校验未通过");
          }
        }
      });

    },
    handleVerify() {
      return new Promise((resolve, reject) => {
        this.$refs.claimFrom.validate((valid, validObj) => {
          if (valid) {
            resolve(true);
          } else {
            let messageList = [];
            // this.$message.error('校验未通过');
            for (const key in validObj) {
              messageList.push(validObj[key][0]);
            }
            if (messageList.length) {
              this.$message.error(messageList[0].message);
              this.$refs[messageList[0].field].$el.scrollIntoView();
            }
            resolve(false);
          }
        });
      });
    },
    initData() {
      this.getDetail();
      getAdjustmentDetail(this.id).then(res => {
        this.form.remark = res.data.remark || '';
        res.data.claimsPayments ? this.form.payeeList = res.data.claimsPayments : this.form.payeeList = [];
        this.form.payeeList.forEach(element => {
          // console.log(element, this.payeeDetail.insClaimsInformationList, '33');
          this.payeeDetail.insClaimsInformationList ? element.payeeInfo = this.payeeDetail.insClaimsInformationList.find((item) => { return item.bankNumber === element.payeeBankNo; }) : '';
        });
        res.data.attachments ? this.picList = res.data.attachments.DJYX : '';
        this.curId = res.data.id;
      //   if (this.isReOpen && this.form.payeeList[this.form.payeeList.length - 1].reopenCount !== this.detail.reopenCount && this.detail.reopenCount !== 0) {
      //     this.form.payeeList.push({ isReAdd: true, payee: '', payeeBankNo: '', payAmount: '', costItem: '', costItemVal: '', reopenCount: this.isReOpen ? this.detail.reopenCount : 0 });
      //   }
      });
      if (this.detail.lastStatus > 8) {
        this.getAudit('3');
      } else if (this.detail.lastStatus == 3 || this.detail.lastStatus == 4 || this.detail.lastStatus == 2) {
        this.getAudit('3');
        this.getAudit('5');
      }
    },
    getCloseList() {
      insClaimsCaseCloseList({ caseId: this.detail.id }).then(res => {
        if (res && res.data && res.data.length) {
          this.closeData = res.data[0];
          if (res.data.id) {
            this.form.remark = res.data.remark;
            this.form.payeeList = res.data.claimsPayments;
            this.form.payeeList.forEach(element => {
              element.payeeInfo = this.payeeDetail.insClaimsInformationList.find((item) => { return item.bankNumber === element.payeeBankNo; });
            });
            this.picList = res.data.attachments.DJYX;
            this.curId = res.data.id;

          }
        }
      });
    },
    auth(v, a) {
      if (a == 'e') {
        return dataPermissions('M21-Edit', v);
      } else if (a == 'v') {
        return dataPermissions('M21-View', v);
      } else if (a == 'a') {
        return dataPermissions('M21-Add', v);
      } else {
        return false;
      }
    },
    handleChangeSelect(e, item, list, key, valueKey) {
      let value = list.filter(val => val.dictKey == e)[0];
      value ? item[key] = value[valueKey] : '';
    },
  },
};
</script>

<style lang="scss" scoped>
.totalMoney {
  margin-top: 10px;
  border-top: 1px solid #e3e3e3;
  text-align: right;
  font-weight: bold;
  font-size: 20px;
}
.detail-text {
  font-size: 14px;
  color: #333;
  margin-top: 20px;
}
.adjustment {
  .el-collapse-item:last-child {
    margin-bottom: 90px;
  }
  .form-content {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    .el-form-item {
      width: 24%;
      margin-right: 30px;
    }
  }
  .payee-info {
    border-radius: 8px;
    margin-top: 24px;
    padding: 10px;
    box-sizing: border-box;
    background-color: #f7fafd;
    .title {
      font-size: 16px;
      display: flex;
      justify-content: space-between;
      font-weight: bold;
    }
  }
}
.operating-box {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: calc(100% - 250px);
  height: 80px;
  padding: 0 24px;
  background: #ffffff;
  box-shadow: 0px -3px 6px 1px rgba(0, 0, 0, 0.16);
  border-radius: 8px;
  z-index: 99;
  position: fixed;
  bottom: 0;
}
::v-deep .el-switch__label {
  font-size: 14px;
  font-weight: 400;
  color: #333333;
}

.select {
  width: 70px;
}
.table-header {
  background: #4278c9ff;
}
.pic-upload {
  margin-top: 24px;
}
.inactive-icon-class {
  font-size: 14px;
}
.lose {
  .item-table {
    .table-main {
      margin-top: 24px;
    }
    .add-item {
      border: 1px solid #dedede;
      display: flex;
      justify-content: center;
      align-items: center;
      color: #00bc0dff;
      font-size: 14px;
      height: 32px;
      .el-icon-circle-plus-outline {
        margin-right: 6px;
      }
    }
  }
  .total-table {
    margin-top: 24px;
    .title-text {
      font-size: 14px;
      font-weight: bold;
      margin-bottom: 12px;
      color: #333333;
    }
    .total-all {
      text-align: right;
      font-size: 14px;
      font-weight: bold;
      color: #333333;
      margin-top: 12px;
    }
  }
}
.tips {
  margin-top: 10px;
}
.required {
  color: red;
}
.approval {
  .switch {
    margin-top: 24px;
  }
}

.surveyor-content {
  margin-top: 24px;
  display: flex;
  align-items: center;
  .surveyorInfo {
    margin-left: 20px;
    display: flex;
    align-items: center;
    .label {
      width: 140px;
    }
    .input {
      width: 300px;
    }
  }
}
.fix-info-main {
  background: #f7fafdff;
  border-radius: 8px;
  opacity: 1;
  padding: 12px;
  .fix-info {
  }
}
</style>
