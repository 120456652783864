<template>
  <div class="claim-step">
    <!-- 索赔 -->
    <el-form label-position="top">
      <AssociationScroll :showTabs="false">
        <AssociationScrollView title="收款方信息录入"
          v-if="auth('insClaimsInformationList','e')"
          name="0">
          <div class="payee-info"
            v-for="item,index in payeeList"
            :ref="'payeeList' + index"
            :key="index">
            <div class="title">
              收款方信息{{index + 1}}
              <template v-if="index === 0">(客户端信息展示)</template>
              ：
              <el-button type="danger"
                v-if="index && edit"
                @click="delList(index)">删除</el-button>
            </div>
            <PicUpload v-model="item.picList"
              class="pic-upload"
              :batchAdd="false"
              :disabled="!edit"
              @getThisPic="ocrPic($event,item)"
              @update="picUpdate(item,'user')"></PicUpload>
            <div class="form-content">
              <el-form-item label="用户类型">
                <el-radio-group v-model="item.payeeType"
                  @change="changeUserType($event,item)"
                  :disabled="!edit">
                  <el-radio label="1">企业</el-radio>
                  <el-radio label="2">个人</el-radio>
                </el-radio-group>
              </el-form-item>
              <template v-if="item.payeeType === '1'">
                <el-form-item label="公司名称"
                  :rules="[{ required: true, message: '不能为空' }]">
                  <!-- <el-input placeholder="请输入"
                    v-if="edit"
                    v-model="item.corporateName"></el-input> -->
                  <el-autocomplete v-if="edit"
                    v-model="item.corporateName"
                    @input="changeCurType(item.payeeType)"
                    :fetch-suggestions="querySearchAsync"
                    :trigger-on-focus="false"
                    placeholder="请输入"
                    style="width:100%"
                    @select="handleSelect($event,index)"></el-autocomplete>
                  <DescriptionsItem v-else
                    v-model="item.corporateName"></DescriptionsItem>
                </el-form-item>
                <el-form-item label="统一社会信用代码"
                  :rules="[{ required: true, message: '不能为空' }]">
                  <el-input placeholder="请输入"
                    v-if="edit"
                    v-model="item.businessLicenseNo"></el-input>
                  <DescriptionsItem v-else
                    v-model="item.businessLicenseNo"></DescriptionsItem>
                </el-form-item>
                <template v-if="lossAmount > 10000">
                  <el-form-item label="法人姓名">
                    <el-input placeholder="请输入"
                      v-if="edit"
                      v-model="item.legalPersonName"></el-input>
                    <DescriptionsItem v-else
                      v-model="item.legalPersonName"></DescriptionsItem>
                  </el-form-item>
                  <el-form-item label="法人身份证号码">
                    <el-input placeholder="请输入"
                      v-if="edit"
                      v-model="item.legalPersonNumber"></el-input>
                    <DescriptionsItem v-else
                      v-model="item.legalPersonNumber"></DescriptionsItem>
                  </el-form-item>
                  <el-form-item label="负责人">
                    <el-input placeholder="请输入"
                      v-if="edit"
                      v-model="item.personInCharge"></el-input>
                    <DescriptionsItem v-else
                      v-model="item.personInCharge"></DescriptionsItem>
                  </el-form-item>
                  <el-form-item label="电话">
                    <el-input placeholder="请输入"
                      v-if="edit"
                      v-model="item.phone"></el-input>
                    <DescriptionsItem v-else
                      v-model="item.phone"></DescriptionsItem>
                  </el-form-item>
                  <el-form-item label="地址">
                    <el-input placeholder="请输入"
                      v-if="edit"
                      v-model="item.address"></el-input>
                    <DescriptionsItem v-else
                      v-model="item.address"></DescriptionsItem>
                  </el-form-item>
                </template>
              </template>
              <template v-else>
                <el-form-item label="姓名"
                  :rules="[{ required: true, message: '不能为空' }]">
                  <!-- <el-input placeholder="请输入"
                    v-if="edit"
                    v-model="item.name"></el-input> -->
                  <el-autocomplete v-if="edit"
                    v-model="item.name"
                    @input="changeCurType(item.payeeType)"
                    :fetch-suggestions="querySearchAsync"
                    :trigger-on-focus="false"
                    placeholder="请输入"
                    style="width:100%"
                    @select="handleSelect($event,index)"></el-autocomplete>
                  <DescriptionsItem v-else
                    v-model="item.name"></DescriptionsItem>
                </el-form-item>
                <el-form-item label="身份证号码"
                  :rules="[{ required: true, message: '不能为空' }]">
                  <el-input placeholder="请输入"
                    v-if="edit"
                    v-model="item.number"></el-input>
                  <DescriptionsItem v-else
                    v-model="item.number"></DescriptionsItem>
                </el-form-item>
                <el-form-item label="电话">
                  <el-input placeholder="请输入"
                    v-if="edit"
                    v-model="item.phone"></el-input>
                  <DescriptionsItem v-else
                    v-model="item.phone"></DescriptionsItem>
                </el-form-item>
                <el-form-item label="地址">
                  <el-input placeholder="请输入"
                    v-if="edit"
                    v-model="item.address"></el-input>
                  <DescriptionsItem v-else
                    v-model="item.address"></DescriptionsItem>
                </el-form-item>
              </template>
              <div class="el-form-item"></div>
            </div>
            <div class="line"></div>
            <div class="bank-info"
              :ref="'bank'+index">
              <div class="title">收款银行资料：</div>
              <div class="tips">
                <!-- <span class="required">*</span> -->
                个人用户请上传银行卡照片，企业用户请上传开户许可证
              </div>
              <PicUpload v-model="item.picList1"
                :batchAdd="false"
                :disabled="!edit"
                @getThisPic="ocrPic($event,item)"
                @update="picUpdate(item,'bank')"></PicUpload>
              <div class="form-content">
                <el-form-item label="开户行"
                  :rules="[{ required: true, message: '不能为空' }]">
                  <el-input placeholder="请输入"
                    v-if="edit"
                    v-model="item.bankOfDeposit"></el-input>
                  <DescriptionsItem v-else
                    v-model="item.bankOfDeposit"></DescriptionsItem>
                </el-form-item>
                <el-form-item label="账号"
                  :rules="[{ required: true, message: '不能为空' }]">
                  <el-input placeholder="请输入"
                    v-if="edit"
                    v-model="item.bankNumber"></el-input>
                  <DescriptionsItem v-else
                    v-model="item.bankNumber"></DescriptionsItem>
                </el-form-item>
                <div class="el-form-item"></div>
              </div>
            </div>
          </div>
          <div class="payee-info"
            v-if="addPublic">
            <div class="title">
              公估方信息：
              <el-button v-if="edit"
                type="danger"
                @click="addPublic = false">删除</el-button>
            </div>
            <PicUpload v-model="publicInfo.picList"
              class="pic-upload"
              :batchAdd="false"
              :disabled="true"></PicUpload>
            <div class="form-content">
              <el-form-item label="营业执照编号"
                :rules="[{ required: true, message: '不能为空' }]">
                <DescriptionsItem v-model="publicInfo.businessLicenseNo"></DescriptionsItem>
              </el-form-item>
              <el-form-item label="公司名称"
                :rules="[{ required: true, message: '不能为空' }]">
                <DescriptionsItem v-model="publicInfo.corporateName"></DescriptionsItem>
              </el-form-item>
              <el-form-item label="负责人">
                <DescriptionsItem v-model="publicInfo.personInCharge"></DescriptionsItem>
              </el-form-item>
              <el-form-item label="电话">
                <DescriptionsItem v-model="publicInfo.phone"></DescriptionsItem>
              </el-form-item>
              <el-form-item label="地址">
                <DescriptionsItem v-model="publicInfo.address"></DescriptionsItem>
              </el-form-item>
              <el-form-item label="传真">
                <DescriptionsItem v-model="publicInfo.fax"></DescriptionsItem>
              </el-form-item>
              <div class="el-form-item"></div>
            </div>
            <div class="line"></div>
            <div class="bank-info"
              :ref="'bank'+index">
              <div class="title">收款银行资料：</div>
              <PicUpload v-model="publicInfo.picList1"
                :batchAdd="false"
                :disabled="true"></PicUpload>
              <div class="form-content">
                <el-form-item label="开户行">
                  <DescriptionsItem v-model="publicInfo.bankOfDeposit"></DescriptionsItem>
                </el-form-item>
                <el-form-item label="账号">
                  <DescriptionsItem v-model="publicInfo.bankNumber"></DescriptionsItem>
                </el-form-item>
                <div class="el-form-item"></div>
              </div>
            </div>
          </div>
          <el-button type="success"
            style="margin-top: 20px"
            v-if="edit"
            @click="addPayee">添加收款方</el-button>
          <el-button type="success"
            style="margin-top: 20px"
            plain
            v-if="edit"
            @click="addPublic = !addPublic">{{addPublic ? '删除':'添加'}}公估方</el-button>
        </AssociationScrollView>
        <AssociationScrollView title="补全单证"
          name="1">
          <PicUpload v-model="attachmentAddReqList"
            class="pic-upload"
            :disabled="!edit"
            @update="picUpdate(attachmentAddReqList,'documents')"></PicUpload>
          <div v-if="!edit && attachmentAddReqList.length === 0">暂无</div>
          <el-form-item label="备注">
            <el-input placeholder="请输入"
              type="textarea"
              v-if="edit"
              v-model="remarks"
              :rows="3"></el-input>
            <DescriptionsItem v-else
              v-model="remarks"></DescriptionsItem>
          </el-form-item>
        </AssociationScrollView>
      </AssociationScroll>
    </el-form>
    <!-- <div class="operating-box">
      <el-button size="mini">返回</el-button>
      <el-button size="mini"
        type="warning">提交</el-button>
      <el-button size="mini"
        type="danger">中止流程</el-button>
    </div>-->
  </div>
</template>

<script>
import AssociationScroll from "@/components/AssociationScroll/AssociationScroll.vue";
import AssociationScrollView from "@/components/AssociationScroll/AssociationScrollView.vue";
import PicUpload from "../PicUpload.vue";
import DescriptionsItem from "../items/DescriptionsItem.vue";
import { saveClaimsDataSheet, getClaimsDataSheetDetail, updateClaimsDataSheet, ocrBankCard, claimLossDetail, ocrID, ocrBankCardAccountOpening, ocrBusinessLicense, getPayHistory } from '@/api/claimData.js';
import { hasPerms, dataPermissions } from "@/utils/auth";
export default {
  name: "LiquidatedDamages",
  components: {
    AssociationScroll,
    AssociationScrollView,
    PicUpload,
    DescriptionsItem
  },
  props: {
    id: {
      type: [String, Number],
      default: "",
    },
    edit: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      payeeList: [{
        payeeType: '2',
        picList: [{ fileName: "身份证头像面", url: "", required: true, defaultPic: require('@/assets/images/正面.png') }, { fileName: "身份证国徽面", url: "", required: true, defaultPic: require('@/assets/images/反面.png') }],
        picList1: [{ fileName: "银行卡", url: "", required: false }],
        number: '',
        phone: '',
        address: '',
        corporateName: '',
        personInCharge: '',
        legalPersonName: '',
        legalPersonNumber: '',
        bankNumber: '',
        bankOfDeposit: '',
        name: '',
        businessLicenseNo: '',
      }],
      attachmentAddReqList: [],
      remarks: '',
      curId: '',
      publicInfo: {
        payeeType: 2,
        picList: [{ fileName: "美捷营业执照", attachmentType: "businessLicense", attachmentUrl: "https://meijie-insurance.oss-cn-guangzhou.aliyuncs.com/meijie/dev/1673508240450/u252.svg", required: true }],
        picList1: [{ fileName: "美捷开户许可证", attachmentType: "bankLicense", attachmentUrl: "https://meijie-insurance.oss-cn-guangzhou.aliyuncs.com/meijie/dev/1673508249361/u69.png", required: false }],
        corporateName: '广州美捷保险公估有限公司',
        businessLicenseNo: '91440902690478051K',
        personInCharge: '吴斌',
        address: '广州市天河区华穗路406号之二2108房',
        phone: '+86-18926188583',
        fax: '',
        bankOfDeposit: '中国农业银行股份有限公司广州中山大道支行',
        bankNumber: '44058701040012324',
      },
      addPublic: false,
      oldData: {},
      lossAmount: null
    };
  },
  created() {
    claimLossDetail({ caseId: this.id }).then(res => {
      let temp = 0;
      res.data && res.data.itemList ? res.data.itemList.forEach(element => {
        temp += element.amount;
      }) : '';
      this.lossAmount = temp;
    });
    getClaimsDataSheetDetail({ caseId: this.id }).then(res => {
      if (res.data.id) {
        res.data.insClaimsInformationList.forEach(el => {
          el.payeeType = el.payeeType + '';
          el.insClaimsAttachmentList.forEach(element => {
            element.required = true;
          });
          if (el.payeeType === '2') {
            // if (el.insClaimsAttachmentList.length === 3) {
            //   el.picList = el.insClaimsAttachmentList.slice(0, 2);
            //   el.picList1 = el.insClaimsAttachmentList.slice(2, 3);
            // } else {
            //   el.picList = el.insClaimsAttachmentList;
            //   el.picList1 = [{ fileName: "银行卡", url: "", required: false }];
            // }
            el.picList = [{ fileName: "身份证头像面", url: "", required: true }, { fileName: "身份证国徽面", url: "", required: true }];
            el.picList1 = [{ fileName: "银行卡", url: "", required: true, }];
            el.insClaimsAttachmentList.forEach(element => {
              if (element.fileName === "银行卡") {
                el.picList1[0] = { ...el.picList1[0], ...element };
              } else if (element.fileName === "身份证头像面") {
                el.picList[0] = { ...el.picList[0], ...element };
              } else if (element.fileName === "身份证国徽面") {
                el.picList[1] = { ...el.picList[1], ...element };
              }
            });
          } else {
            if (this.lossAmount > 10000) {
              el.picList = [{ fileName: "营业执照", url: "", required: true, }, { fileName: "法人身份证-头像面", url: "", required: true }, { fileName: "法人身份证-国徽面", url: "", required: true }, { fileName: "企查查企业截图", url: "", required: true },];
            } else {
              el.picList = [{ fileName: "营业执照", url: "", required: true, }];
            }
            el.picList1 = [{ fileName: "开户许可证", url: "", required: true }];
            el.insClaimsAttachmentList.forEach(element => {
              if (element.fileName === "开户许可证") {
                el.picList1[0] = { ...el.picList1[0], ...element };
              } else if (element.fileName === "营业执照") {
                el.picList[0] = { ...el.picList[0], ...element };
              } else if (element.fileName === "法人身份证-头像面") {
                el.picList[1] = { ...el.picList[1], ...element };
              } else if (element.fileName === "法人身份证-国徽面") {
                el.picList[2] = { ...el.picList[2], ...element };
              } else if (element.fileName === "企查查企业截图") {
                el.picList[3] = { ...el.picList[3], ...element };
              }
            });
            // if (el.insClaimsAttachmentList.length === 5) {
            //   el.picList = el.insClaimsAttachmentList.slice(0, 4);
            //   el.picList1 = el.insClaimsAttachmentList.slice(4, 5);
            // } else if (el.insClaimsAttachmentList.length === 4) {  //金额超过10000且没银行卡
            //   el.picList = el.insClaimsAttachmentList.slice(0, 4);
            //   el.picList1 = [{ fileName: "开户许可证", url: "", required: false }];
            // } else if (el.insClaimsAttachmentList.length === 2) {  //金额低于10000
            //   el.picList = [el.insClaimsAttachmentList[0]];
            //   el.picList1 = [el.insClaimsAttachmentList[1]];
            // } else if (el.insClaimsAttachmentList.length === 1) {
            //   el.picList = el.insClaimsAttachmentList;
            // }
          }
        });
        this.remarks = res.data.remarks;
        this.attachmentAddReqList = res.data.attachmentAddReqList;
        this.payeeList = res.data.insClaimsInformationList;
        this.curId = res.data.id;
        if (res.data.insClaimsAssessorList.length > 0) this.addPublic = true;

        this.oldData.remarks = res.data.remarks || this.remarks;
        this.oldData.attachmentAddReqList = res.data.attachmentAddReqList || this.attachmentAddReqList;
        this.oldData.payeeList = res.data.insClaimsInformationList || this.payeeList;
        this.oldData.addPublic = res.data.insClaimsAssessorList ? true : false;
      } else {
        this.reset();
      }
    }, err => { this.reset(); });

  },
  methods: {
    reset() {
      this.oldData.addPublic = false;
      this.oldData.payeeList = [{
        payeeType: '2',
        picList: [{ fileName: "身份证头像面", url: "", required: true }, { fileName: "身份证国徽面", url: "", required: true }],
        picList1: [{ fileName: "银行卡", url: "", required: true }],
        number: '',
        phone: '',
        address: '',
        corporateName: '',
        personInCharge: '',
        legalPersonName: '',
        legalPersonNumber: '',
        bankNumber: '',
        bankOfDeposit: '',
        name: '',
        businessLicenseNo: '',
      }];
      this.oldData.attachmentAddReqList = [];
      this.oldData.remarks = '';
    },
    changeUserType(value, item) {
      if (value === '1') {
        if (this.lossAmount > 10000) {
          item.picList = [{ fileName: "营业执照", url: "", required: true, }, { fileName: "法人身份证-头像面", url: "", required: true }, { fileName: "法人身份证-国徽面", url: "", required: true }, { fileName: "企查查企业截图", url: "", required: true },];
        } else {
          item.picList = [{ fileName: "营业执照", url: "", required: true, }];
        }
        item.picList1 = [{ fileName: "开户许可证", url: "", required: true }];
        item.name = '';
        item.number = '';
      } else {
        item.picList = [{ fileName: "身份证头像面", url: "", required: true }, { fileName: "身份证国徽面", url: "", required: true }];
        item.picList1 = [{ fileName: "银行卡", url: "", required: true }];
        item.corporateName = '';
        item.businessLicenseNo = '';
        item.legalPersonName = '';
        item.legalPersonNumber = '';
        item.personInCharge = '';
      }
      item.address = '';
      item.phone = '';

    },
    addPayee() {
      this.payeeList.push({
        payeeType: '2',
        picList: [{ fileName: "身份证头像面", url: "", required: true, }, { fileName: "身份证国徽面", url: "", required: true }],
        picList1: [{ fileName: "银行卡", url: "", required: true, }],
        number: '',
        phone: '',
        address: '',
        corporateName: '',
        personInCharge: '',
        legalPersonName: '',
        legalPersonNumber: '',
        bankNumber: '',
        bankOfDeposit: '',
        name: '',
      });
    },
    delList(index) {
      this.payeeList.splice(index, 1);
    },
    handleSave() {
      let warn = false;
      let tempList = JSON.parse(JSON.stringify(this.payeeList));
      tempList.forEach((el, ind) => {
        if (warn) {
          throw new Error('校验错误');
        }
        el.attachmentAddReqList = [...el.picList, ...el.picList1];
        console.log(el.attachmentAddReqList);
        if (el.attachmentAddReqList.length) {
          el.attachmentAddReqList.forEach(element => {
            if (element.bussId) delete element.bussId;
          });
        }
        if (el.payeeType === '2') {
          if (!el.name || !el.number || !el.bankOfDeposit || !el.bankNumber) {
            warn = true;
            !el.bankOfDeposit || !el.bankNumber ? this.$refs['bank' + ind][0].scrollIntoView() : this.$refs['payeeList' + ind][0].scrollIntoView();
          };
        } else {
          if (!el.corporateName || !el.businessLicenseNo || !el.bankOfDeposit || !el.bankNumber) {
            warn = true;
            !el.bankOfDeposit || !el.bankNumber ? this.$refs['bank' + ind][0].scrollIntoView() : this.$refs['payeeList' + ind][0].scrollIntoView();
          }
        }
        for (let index = 0; index < el.attachmentAddReqList.length; index++) {
          const element = el.attachmentAddReqList[index];
          if ((element.fileName === '银行卡' || element.fileName === '开户许可证') && !element.attachmentUrl) {
            el.attachmentAddReqList.splice(index, 1);
            index--;
          }
          if (!element.attachmentUrl && (element.fileName !== '银行卡' && element.fileName !== '开户许可证')) {
            warn = true;
            this.$refs['payeeList' + ind][0].scrollIntoView();
          }
        }
      });
      this.publicInfo.attachmentAddReqList = [...this.publicInfo.picList, ...this.publicInfo.picList1];
      this.publicInfo.attachmentAddReqList.forEach(el => {
        el.bussId = this.curId;
      });
      this.attachmentAddReqList.forEach(el => {
        if (!el.bussId) {
          el.bussId = this.curId;
        }
      });
      let data = {
        flag: 1,
        caseId: this.id,
        remarks: this.remarks,
        attachmentAddReqList: this.attachmentAddReqList,
        insClaimsInformationList: tempList,
        insClaimsAssessorList: this.addPublic ? [this.publicInfo] : []
      };
      if (warn) return this.$message.error('完善表单数据');
      if (this.curId) {
        data.id = this.curId;
        updateClaimsDataSheet(data).then(res => {
          if (res) {
            this.$emit("Submit");
            this.$message.success(res.msg);
          }
        });
      } else {
        saveClaimsDataSheet(data).then(res => {
          if (res) {
            this.$emit("Submit");
            this.$message.success(res.msg);
          }
        });
      }
    },
    ocrPic(info, item) {
      const type = info.attachmentType;
      var port = null;
      if (type === "frontCard" || type === "faceFrontCard") {
        port = ocrID;
        port({ positiveImageOssUrl: info.attachmentUrl, backImageOssUrl: '' }).then(res => {
          if (res) {
            item.address = res.data.address;
            if (item.payeeType === '2') {
              item.name = res.data.name;
              item.number = res.data.idnumber;
            } else {
              item.legalPersonName = res.data.name;
              item.legalPersonNumber = res.data.idnumber;
            }
          }
        });
      } else if (type === 'receivingBank') {
        ocrBankCard({ ossUrl: info.attachmentUrl }).then(res => {
          item.bankOfDeposit = res.data.bankName;
          item.bankNumber = res.data.cardNumber;
        });
      } else if (type === 'businessLicense') {
        ocrBusinessLicense({ ossUrl: info.attachmentUrl }).then(res => {
          item.corporateName = res.data.companyName;
          item.businessLicenseNo = res.data.creditCode;
        });
      } else if (type === 'bankLicense') {
        ocrBankCardAccountOpening({ ossUrl: info.attachmentUrl }).then(res => {
          item.bankOfDeposit = res.data.depositaryBank;
          item.bankNumber = res.data.bankAccount;
        });
      }

    },
    picUpdate(data, type) {
      if (type === 'user') {
        if (data.payeeType === '2') {
          data.picList[0].fileName = "身份证头像面";
          data.picList[1].fileName = "身份证国徽面";
          data.picList[0].attachmentType = "frontCard";
          data.picList[1].attachmentType = "backCard";
        } else {
          data.picList[0].fileName = "营业执照";
          data.picList[0].attachmentType = "businessLicense";
          if (this.lossAmount > 10000) {
            data.picList[1].fileName = "法人身份证-头像面";
            data.picList[2].fileName = "法人身份证-国徽面";
            data.picList[3].fileName = "企查查企业截图";
            data.picList[1].attachmentType = "faceFrontCard";
            data.picList[2].attachmentType = "faceBackCard";
            data.picList[3].attachmentType = "inspectionScreenshot";
          }
        }
      } else if (type === 'bank') {
        if (data.payeeType === '2') {
          data.picList1[0].fileName = '银行卡';
          data.picList1[0].attachmentType = "receivingBank";
        } else {
          data.picList1[0].fileName = '开户许可证';
          data.picList1[0].attachmentType = "bankLicense";
        }
      } else if (type === 'documents') {
        data.forEach(element => {
          element.attachmentType = 'documents';
        });
      }
    },
    handleReset() {
      Object.keys(this.oldData).forEach((key) => {
        if (
          this.oldData[key] instanceof Array ||
          this.oldData[key] instanceof Object
        ) {
          this[key] = _.cloneDeep(this.oldData[key]);
        } else {
          this[key] = this.oldData[key];
        }
      });
      this.$forceUpdate();
    },
    querySearchAsync(val, cb) {
      getPayHistory({ payeeType: this.curType, name: val }).then(res => {
        let cbList = res.data && res.data.length ? res.data.map(el => {
          el.value = el.name || el.corporateName;
          return el;
        }) : [];
        cb(cbList);
      }).catch(err => {
        cb([]);
      });
    },
    changeCurType(type) {
      this.curType = type;
    },
    handleSelect(item, index) {
      item.payeeType = item.payeeType + '';
      item.insClaimsAttachmentList.forEach(element => {
        element.required = true;
      });
      if (item.payeeType === '2') {
        if (item.insClaimsAttachmentList.length === 3) {
          item.picList = item.insClaimsAttachmentList.slice(0, 2);
          item.picList1 = item.insClaimsAttachmentList.slice(2, 3);
        } else {
          item.picList = item.insClaimsAttachmentList;
          item.picList1 = [{ fileName: "银行卡", url: "", required: false }];
        }
      } else {
        if (item.insClaimsAttachmentList.length === 5) {
          item.picList = item.insClaimsAttachmentList.slice(0, 4);
          item.picList1 = item.insClaimsAttachmentList.slice(4, 5);
        } else if (item.insClaimsAttachmentList.length === 4) {  //金额超过10000且没银行卡
          item.picList = item.insClaimsAttachmentList.slice(0, 4);
          item.picList1 = [{ fileName: "开户许可证", url: "", required: false }];
        } else if (item.insClaimsAttachmentList.length === 2) {  //金额低于10000
          item.picList = [item.insClaimsAttachmentList[0]];
          item.picList1 = [item.insClaimsAttachmentList[1]];
        } else if (item.insClaimsAttachmentList.length === 1) {
          item.picList = item.insClaimsAttachmentList;
        }
      }
      this.payeeList[index] = item;
      console.log(this.payeeList);
      this.$forceUpdate();
    },
    auth(v, a) {
      if (a == 'e') {
        return dataPermissions('M21-Edit', v);
      } else if (a == 'v') {
        return dataPermissions('M21-View', v);
      } else if (a == 'a') {
        return dataPermissions('M21-Add', v);
      } else {
        return false;
      }
    }
  },
};
</script>

<style lang="scss" scoped>
.claim-step {
  .el-collapse-item:last-child {
    margin-bottom: 90px;
  }
  .payee-info {
    padding: 10px;
    border-radius: 8px;
    box-sizing: border-box;
    background-color: #f7fafd;
    margin-top: 24px;
    .title {
      display: flex;
      justify-content: space-between;
      font-weight: bold;
    }
    .form-content {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      .el-form-item {
        width: 24%;
        margin-right: 30px;
      }
    }
  }
  .line {
    width: 100%;
    border-bottom: 2px dashed #999;
    margin: 10px 0 20px;
  }
}
.operating-box {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: calc(100% - 250px);
  height: 80px;
  padding: 0 24px;
  background: #ffffff;
  box-shadow: 0px -3px 6px 1px rgba(0, 0, 0, 0.16);
  border-radius: 8px;
  z-index: 99;
  position: fixed;
  bottom: 0;
}
::v-deep .el-switch__label {
  font-size: 14px;
  font-weight: 400;
  color: #333333;
}

.select {
  width: 70px;
}
.table-header {
  background: #4278c9ff;
}
.pic-upload {
  margin-top: 10px;
}
.inactive-icon-class {
  font-size: 14px;
}
.lose {
  .item-table {
    .table-main {
      margin-top: 24px;
    }
    .add-item {
      border: 1px solid #dedede;
      display: flex;
      justify-content: center;
      align-items: center;
      color: #00bc0dff;
      font-size: 14px;
      height: 32px;
      .el-icon-circle-plus-outline {
        margin-right: 6px;
      }
    }
  }
  .total-table {
    margin-top: 24px;
    .title-text {
      font-size: 14px;
      font-weight: bold;
      margin-bottom: 12px;
      color: #333333;
    }
    .total-all {
      text-align: right;
      font-size: 14px;
      font-weight: bold;
      color: #333333;
      margin-top: 12px;
    }
  }
}
.tips {
  margin-top: 10px;
}
.required {
  color: red;
}
.approval {
  .switch {
    margin-top: 24px;
  }
}
.surveyor-content {
  margin-top: 24px;
  display: flex;
  align-items: center;
  .surveyorInfo {
    margin-left: 20px;
    display: flex;
    align-items: center;
    .label {
      width: 140px;
    }
    .input {
      width: 300px;
    }
  }
}
.fix-info-main {
  background: #f7fafdff;
  border-radius: 8px;
  opacity: 1;
  padding: 12px;
  .fix-info {
  }
}
</style>
