<template>
  <el-dialog destroy-on-close :visible.sync="dialogVisible" @close="close">
    <template #title>
      <span class="dialog-title">理赔中止</span>
    </template>
    <template #footer>
      <div>
        <el-button @click="close">返回</el-button>
        <el-button type="primary" @click="confirm">确定中止</el-button>
      </div>
    </template>
    <el-form
      class="form"
      label-position="top"
      :model="ruleForm"
      :rules="rules"
      ref="ruleForm"
    >
      <!-- 理赔中止 -->
      <div class="item">
        <div class="title">
          <span class="title-text">理赔中止原因</span>
        </div>
        <el-form-item prop="suspendReasonValue">
          <template #label>
            <span class="label">选择中止理赔原因</span>
          </template>
          <el-select
            placeholder="请选择"
            v-model="ruleForm.suspendReasonValue"
            @change="handleChangeSelect"
          >
            <el-option
              v-for="item in reasonOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item prop="suspendReasonRemark">
          <template #label>
            <span class="label">备注</span>
          </template>
          <el-input
            type="textarea"
            :rows="4"
            v-model="ruleForm.suspendReasonRemark"
            placeholder="请输入备注"
          ></el-input>
        </el-form-item>
      </div>
      <!-- 客户补充资料 -->
      <div>
        <div class="title">
          <span class="title-text">客户补充资料</span>
        </div>
        <el-form-item>
          <PicUpload ref="picUpload" v-model="ruleForm.suspendReplenishInfo"></PicUpload>
        </el-form-item>
        <el-form-item prop="suspendReplenishRemark" required>
          <template #label>
            <span class="label">备注</span>
          </template>
          <el-input
            type="textarea"
            :rows="4"
            placeholder="请输入备注"
            v-model="ruleForm.suspendReplenishRemark"
          ></el-input>
        </el-form-item>
      </div>
    </el-form>
  </el-dialog>
</template>

<script>
import PicUpload from '../PicUpload.vue';
export default {
  name: "claimStopDB",
  components: { PicUpload },
  props: {
    show: {
      type: Boolean,
      default: false,
    }
  },
  watch: {
    dialogVisible(n) {
      this.$emit("update:show", n);
    },
    show(n) {
      this.dialogVisible = n;
      if (!n) {
        this.ruleForm = {
          suspendReasonName: '客户放弃索赔',
          suspendReasonRemark: '',
          suspendReasonValue: 1,
          suspendReplenishInfo: [],
          suspendReplenishRemark: ''
        };
      }
    }
  },
  data() {
    return {
      imgList: [],
      dialogVisible: false,
      reasonOptions: [
        { value: 1, label: "客户放弃索赔" },
        { value: 2, label: "非保险责任" },
        { value: 3, label: "拒赔" },
        { value: 4, label: "重新报案" },
        { value: 5, label: "其他原因" },
      ],
      ruleForm: {
        suspendReasonName: '客户放弃索赔',
        suspendReasonRemark: '',
        suspendReasonValue: 1,
        suspendReplenishInfo: [],
        suspendReplenishRemark: ''
      },
      rules: {
        suspendReasonName: [{ required: true, message: '选择中止原因', trigger: 'change' },],
        suspendReasonRemark: [{ required: true, message: '输入中止原因备注', trigger: 'change' },],
        suspendReplenishRemark: [{ required: true, message: '输入客户补充资料备注', trigger: 'change' },],
      }
    };
  },
  methods: {
    confirm() {
      this.$refs['ruleForm'].validate((valid) => {
        if (valid) {
          this.$confirm('是否确定中止流程?', '中止流程', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
          }).then(() => {
            let ruleForm = JSON.parse(JSON.stringify(this.ruleForm));
            ruleForm.suspendReplenishInfo = JSON.stringify(ruleForm.suspendReplenishInfo);
            this.$message({
              type: 'success',
              message: '中止成功!'
            });
            this.$emit('submit', ruleForm);
          }).catch(() => {
            this.$message({
              type: 'info',
              message: '已取消'
            });
          });
        }
      });
    },
    close() {
      this.dialogVisible = false;
    },
    handleChangeSelect(e) {
      this.ruleForm.suspendReasonName = this.reasonOptions[e - 1].label;
    }
  }
};
</script>

<style lang="scss" scoped>
::v-deep .el-dialog__header {
  background: #4278c9;
  border-radius: 8px 8px 0px 0px;
  opacity: 1;
  height: 50px;
}
::v-deep .el-dialog {
  border-radius: 8px;
  width: 640px;
}
.dialog-title {
  font-size: 18px;
  font-weight: bold;
  color: #ffffff;
  line-height: 0px;
}
.form {
  margin: 0 12px;
  .title {
    display: flex;
    align-items: center;
    font-size: 16px;
    font-weight: bold;
    color: #333333;
    line-height: 0px;
    margin-bottom: 19px;
    .title-text {
      margin: 0 12px 0 8px;
    }
  }
  .title::before {
    content: '';
    display: inline-block;
    width: 6px;
    height: 18px;
    background: #4278c9;
    border-radius: 4px 4px 4px 4px;
    opacity: 1;
  }
  .title::after {
    content: '';
    display: inline-block;
    width: 75%;
    height: 1px;
    background: #ccccccff;
    opacity: 1;
    text-align: right;
  }
}
</style>
