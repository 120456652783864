<template>
  <div class="main">
    <!-- 图片展示 -->
    <draggable v-model="picList"
      :disabled="!canDrag || disabled"
      forceFallback
      @end="endDrag"
      class="draggable">
      <!-- <div class="draggable"> -->
      <div class="upload-item"
        v-for="(pic, index) in picList"
        :key="index"
        @click="preView(pic, false, index)">
        <el-upload :disabled="disabled"
          class="avatar-uploader middle"
          action="#"
          :http-request="() => {}"
          :show-file-list="false"
          :on-change="
            (file, fileList) => {
              uploadChange(file, fileList, index);
            }
          ">
          <el-image class="show-pic"
            v-if="pic.attachmentUrl && !pic.isV"
            fit="contain"
            :src="pic.attachmentUrl">
            <div slot="error"
              class="image-slot">
              <el-image fit="contain"
                :src="blobType[pic.fileType]?require(`@/assets/images/docTypesImg/${pic.fileType}.png`):require(`@/assets/images/docTypesImg/other.png`)" />
            </div>
          </el-image>
          <video width="100%"
            v-else-if="pic.attachmentUrl && pic.isV"
            :src="pic.attachmentUrl"></video>
          <el-image class="defaulPic"
            fit="contain"
            v-else-if="pic.defaultPic"
            :src="pic.defaultPic" />
          <el-image class="defaulPic"
            fit="contain"
            v-else
            :src="require('@/assets/images/uploadDefPic.png')" />
          <!-- <i v-else class="el-icon-picture-outline"></i> -->
          <i v-if="pic.attachmentUrl && !disabled"
            @click.stop="deletPic(index)"
            class="el-icon-circle-close" />
          <i v-if="pic.attachmentUrl && !disabled"
            @click.stop="preView(pic, true, index)"
            class="el-icon-zoom-in" />
        </el-upload>
        <div class="input-text">
          <span class="red-star"
            v-if="pic.required">*</span>
          <div v-if="pic.required"
            class="input">{{ pic.fileName }}</div>
          <el-input @input="inputName"
            :disabled="disabled"
            v-else
            class="input"
            v-model="pic.fileName"></el-input>
        </div>
      </div>
      <!-- </div> -->
    </draggable>
    <!-- 批量添加 -->
    <div class="upload-item"
      v-if="!disabled && batchAdd">
      <el-upload class="avatar-uploader"
        action="#"
        multiple
        :http-request="() => {}"
        :show-file-list="false"
        :on-change="batchUploadChange">
        <div class="middle">
          <i class="el-icon-plus avatar-uploader-icon"></i>
          <div class="i-text">批量添加</div>
        </div>
      </el-upload>
    </div>
    <el-dialog :visible.sync="predialogVisible">
      <div class="pic-pre">
        <i @click="nextPre(-1)"
          class="el-icon-caret-left pre-i left-i"></i>
        <video v-if="preIsV"
          width="auto"
          height="600px"
          controls
          :src="preImageUrl"></video>
        <img width="auto"
          height="600px"
          :src="preImageUrl"
          alt="" />
        <i @click="nextPre(1)"
          class="el-icon-caret-right pre-i right-i"></i>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { OSSDirectPass } from "@/api/oss.js";
import { fileToBase64 } from "@/utils";
import draggable from "vuedraggable";
import _ from "lodash";
import { blobType2, blobType } from '@/config/fileType.js';
export default {
  name: "PicUpload",
  components: { draggable },
  model: {
    event: "update",
    prop: "imgList",
  },
  props: {
    imgList: {
      type: Array,
      default: () => [],
    },
    picSpText: {
      type: Boolean,
      default: false,
    },
    batchAdd: {
      type: Boolean,
      default: true,
    },
    init: {
      type: Number,
      default: 0,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    limitType: {
      type: Array,
      default: () => [],
    },
    itemReturnType: {
      type: String,
      default: "",
    },
    limitSize: {
      type: Number,
      default: 4,
    },
    isFinalSubmission: {
      type: Boolean,
      default: false,
    },
    canDrag: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      predialogVisible: false,
      preImageUrl: "",
      preIsV: false,
      picList: [],
      fileList: [],
      textList: [],
      urlList: [],
      preIndex: "",
      blobType2: blobType2,
      blobType: blobType
    };
  },
  watch: {
    imgList: {
      immediate: true,
      async handler(n) {
        this.urlList = [];
        if (n.length) {
          await this.$nextTick();
          this.picList = n.map((item) => {
            this.urlList.push(item.attachmentUrl);
            return {
              ...item,
              isV: item.fileType == "video/mp4" || item.fileType == "mp4",
            };
          });
        } else {
          //  this.picList = [{
          //    fileName: "",
          //    attachmentUrl: "",
          //    fileType: "",
          //  }];
        }
      },
    },
    init: {
      immediate: true,
      handler(n) {
        if (n) {
          this.picList = [];
          for (let i = 0; i < n; i++) {
            this.picList.push({
              fileName: "",
              attachmentUrl: "",
              fileType: "",
            });
          }
        }
      },
    },
  },
  computed: {},
  methods: {
    async batchUploadChange(file, fileList) {
      if (this.sizeTips(file)) {
        return;
      }
      let attachmentUrl = "";
      if (this.isFinalSubmission) {
        attachmentUrl = await fileToBase64(file.raw);
      } else {
        attachmentUrl = await OSSDirectPass(file);
      }
      if (attachmentUrl) {
        let accumulative = 0;
        if (this.init) {
          for (let i = 0; i < this.init; i++) {
            if (!this.picList[i].attachmentUrl) {
              if (this.isFinalSubmission) {
                this.fileList.splice(i, 1, file);
              }
              this.urlList.splice(i, 1, attachmentUrl);
              this.picList.splice(
                i,
                1,
                Object.assign(this.picList[i], {
                  fileName: file.name,
                  attachmentUrl,
                  required: this.picList[i].required,
                  fileType: this.blobType2[file.raw.type || 'other'],
                  isV:
                    file.raw.type == "video/mp4" || file.raw.fileType == "mp4",
                })
              );
              break;
            } else {
              accumulative++;
            }
          }
        }
        if (accumulative >= this.init || !this.init) {
          this.urlList.push(attachmentUrl);
          this.fileList.push(file);
          this.picList.push({
            fileName: file.name,
            attachmentUrl,
            isV: file.raw.type == "video/mp4" || file.raw.fileType == "mp4",
            fileType: this.blobType2[file.raw.type || 'other'],
          });
        }
      }
      if (this.picSpText) {
        this.textList.push({ fileName: file.name });
      }
      this.$emit("update", this.picList);
    },
    async uploadChange(file, fileList, index) {
      if (this.sizeTips(file)) {
        return;
      }
      let attachmentUrl = "";
      if (this.isFinalSubmission) {
        attachmentUrl = await fileToBase64(file.raw);
        this.urlList.splice(index, 1, attachmentUrl);
        this.fileList.splice(index, 1, file);
      } else {
        attachmentUrl = await OSSDirectPass(file);
      }
      let fileData = Object.assign(this.picList[index], {
        fileName: file.name,
        attachmentUrl,
        required: this.picList[index].required,
        fileType: this.blobType2[file.raw.type || 'other'],
        isV: file.raw.type == "video/mp4" || file.raw.fileType == "mp4",
      });
      this.picList.splice(index, 1, fileData);
      if (this.itemReturnType == "Base64") {
        let Base64 = await fileToBase64(file.raw);
        fileData.Base64 = Base64;
      }
      this.$emit("update", this.picList);
      this.$emit("getThisPic", fileData);
    },
    deletPic(index) {
      if (this.picList[index].required) {
        this.picList[index].attachmentUrl = "";
        this.urlList.splice(index, 0, "");
        this.$emit("update", this.picList);
        return;
      }
      if (this.picList.length <= this.init) {
        this.picList[index].attachmentUrl = "";
        this.picList[index].fileName = "";
        this.urlList.splice(index, 0, "");
      } else {
        this.urlList.splice(index, 1);
        this.picList.splice(index, 1);
      }
      this.$emit("update", this.picList);
    },
    getPicList() {
      if (this.picList == 1 && !this.picList[0].attachmentUrl) {
        return [];
      } else {
        return this.picList;
      }
    },
    inputName() {
      this.$emit("update", this.picList);
    },
    sizeTips(file, index) {
      let size = this.limitSize;
      let fileSize = file.size / 1024 / 1024;
      if (fileSize > size) {
        this.$message.error(`上传文件不可以大于${ this.limitSize }M`);
        return true;
      }
      return false;
    },
    async finalSubmit() {
      if (this.isFinalSubmission) {
        for (let i = 0; i < this.fileList; i++) {
          let attachmentUrl = await OSSDirectPass(file);
          if (attachmentUrl) {
            this.picList[i].attachmentUrl = attachmentUrl;
          } else {
            this.$message.error(`上传文件失败！`);
            break;
          }
        }
      }
    },
    preView(pic, tapIcon, index) {
      console.log(pic, tapIcon, index, '33');
      if ((this.disabled && pic.attachmentUrl) || tapIcon) {
        return window.open(pic.attachmentUrl, '_blank');
        this.preIsV = pic.isV;
        this.preImageUrl = pic.attachmentUrl;
        this.preIndex = index;
        this.predialogVisible = true;
      }
    },
    endDrag(e) {
      if (this.picSpText) {
        let oldAttachmentUrl = this.picList[e.oldIndex].attachmentUrl;
        let newAttachmentUrl = this.picList[e.newIndex].attachmentUrl;
        let oldObj = _.cloneDeep(this.picList[e.oldIndex]);
        let newObj = _.cloneDeep(this.picList[e.newIndex]);
        oldObj.attachmentUrl = newAttachmentUrl;
        newObj.attachmentUrl = oldAttachmentUrl;
        this.picList.splice(e.oldIndex, 1, newObj);
        this.picList.splice(e.newIndex, 1, oldObj);
        this.$forceUpdate();
        this.$emit("update", this.picList);
      }
    },
    nextPre(num = 0) {
      let nextIndex = Number(this.preIndex + num);
      if (nextIndex >= 0 && nextIndex < this.urlList.length) {
        this.preIndex = nextIndex;
        this.preIsV = this.picList[nextIndex].isV;
        this.preImageUrl = this.urlList[nextIndex];
      }
      this.$forceUpdate();
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .el-image {
  display: block;
}
.main {
  display: flex;
  flex-wrap: wrap;
  .upload-item {
    margin-right: 24px;
    margin-bottom: 12px;
    display: column;
    justify-content: center;
  }
  .input-text {
    display: flex;
    justify-content: center;
    align-items: flex-end;
    .red-star {
      color: #f56c6c;
    }
    .input {
      width: 150px;
      margin-top: 12px;
      text-align: center;
    }
  }

  .pic-show {
    width: 160px;
    height: 160px;
  }
  .draggable {
    display: flex;
    flex-wrap: wrap;
  }
  .avatar-uploader {
    width: 161px;
    height: 161px;
    background: #ffffff;
    border-radius: 8px 8px 8px 8px;
    opacity: 1;
    border: 1px solid #666666;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    overflow: hidden;
    .show-pic {
      width: 160px;
      height: 160px;
    }
    .el-icon-picture-outline {
      font-size: 100px;
    }
    .el-icon-circle-close {
      position: absolute;
      top: 8px;
      right: 8px;
      font-size: 24px;
      color: #666666ff;
    }
    .el-icon-zoom-in {
      position: absolute;
      top: 8px;
      left: 8px;
      font-size: 24px;
      color: #666666ff;
    }
    .middle {
      display: column;

      .i-text {
        font-size: 14px;
        font-weight: 400;
        color: #333333;
      }

      .avatar-uploader-icon {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 80px;
        height: 80px;
        //border: 1px dashed #666666;
      }

      .el-icon-plus {
        font-size: 48px;
        font-weight: 800;
        color: #d1d1d1ff;
      }
    }
  }
}
.defaulPic {
  width: 132px;
  height: 132px;
}
.pic-pre {
  display: flex;
  align-items: center;
  justify-content: center;
  width: auto;
  .pre-i {
    font-size: 24px;
  }
  .left-i {
  }
  .right-i {
  }
}
</style>
