<template>
  <div class="suspension-box">
    <div class="type-title">案件已中止</div>
    <div class="suspension">
      <div>
        <div class="title">
          <span class="title-text">理赔中止原因</span>
        </div>
        <div class="item-box">
          <div class="item-conten">中止理赔原因：{{detail.suspendReasonName}}</div>
          <div class="item-conten" style="margin-bottom: 12px">备注</div>
          <div class="item-textarea item-conten">{{detail.suspendReasonRemark}}</div>
        </div>
      </div>
      <div>
        <div class="title">
          <span class="title-text">客户补充材料</span>
        </div>
        <div class="item-box">
          <div class="item-conten">
            <div
              v-for="item,index in JSON.parse(detail.suspendReplenishInfo)"
              :key="index"
              style="margin-right: 10px"
            >
              <el-image
                style="width: 240px;height:240px"
                :src="item.attachmentUrl || item.url"
              ></el-image>
              <div class="img-title">{{ item.fileName }}</div>
            </div>
          </div>
          <div class="item-conten" style="margin-bottom: 12px">备注</div>
          <div
            class="item-textarea item-conten"
          >{{detail.suspendReplenishRemark}}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    detail: {
      type: Object,
      default: () => { suspendReplenishInfo: ''; }
    }
  }
}
</script>

<style lang="scss" scoped>
.suspension-box {
  margin-bottom: 10px;
  border-radius: 8px;
  border: none;
  background-color: #fff;
  overflow: hidden;
}
.type-title {
  height: 60px;
  text-align: center;
  line-height: 60px;
  background: #fca118;
  font-size: 20px;
  font-weight: bold;
  color: #ffffff;
}
.suspension {
  padding: 24px;
  .title {
    display: flex;
    align-items: center;
    font-size: 16px;
    font-weight: bold;
    color: #333333;
    line-height: 0px;
    margin-bottom: 19px;
    .title-text {
      margin: 0 12px 0 8px;
    }
  }
  .title::before {
    content: '';
    display: inline-block;
    width: 6px;
    height: 18px;
    background: #4278c9;
    border-radius: 4px 4px 4px 4px;
    opacity: 1;
  }
  .title::after {
    content: '';
    display: inline-block;
    width: 75%;
    height: 1px;
    background: #ccccccff;
    opacity: 1;
    text-align: right;
  }
  .item-box {
    margin: 0 12px;
    .item-conten {
      display: flex;
      font-size: 14px;
      color: #333333;
      margin-bottom: 24px;
    }
    .item-textarea {
      padding: 8px 12px;
      border-radius: 4px 4px 4px 4px;
      border: 1px solid #cccccc;
    }
    .img-title {
      margin-top: 10px;
    }
  }
}
</style>