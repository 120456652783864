<template>
  <el-dialog :visible="showDialog"
    width="86%"
    @close="close">
    <template #title>
      <span class="dialog-title">确认结案</span>
    </template>
    <template #footer>
      <div>
        <el-button @click="close">取 消</el-button>
        <el-button @click="submit"
          type="primary">确 认</el-button>
      </div>
    </template>
    <div class="suspension-box">
      <div class="suspension">
        <div>
          <div class="title">
            <span class="title-text">支付信息</span>
          </div>
          <div class="item-box">
            <el-form label-position="top"
              ref="claimFrom"
              :model="rulesData"
              :rules="rulesClaimFrom">
              <el-table :data="rulesData.payeeList"
                border
                style="margin: 0;">
                <el-table-column label="序号"
                  type="index"
                  width="50"></el-table-column>
                <el-table-column label="收款方"
                  prop="payeeInfo"
                  width="180">
                  <template slot-scope="{row, $index}">
                    <el-form-item :prop="'payeeList.' + $index + '.payee'"
                      :ref="'payeeList.' + $index + '.payee'"
                      :rules="[{ required: true, message: '请选择收款方' }]">
                      <DescriptionsItem v-model="row.payee"></DescriptionsItem>
                    </el-form-item>
                  </template>
                </el-table-column>
                <el-table-column label="收款方账号"
                  prop="payeeBankNo"
                  width="180">
                  <template slot-scope="{row}">
                    <DescriptionsItem v-model="row.payeeBankNo"></DescriptionsItem>
                  </template>
                </el-table-column>
                <el-table-column label="费用项"
                  width="160"
                  prop="reopenCount">
                  <template slot-scope="{row, $index}">
                    <DescriptionsItem v-model="row.costItem"></DescriptionsItem>
                  </template>
                </el-table-column>
                <el-table-column label="金额"
                  prop="payAmount"
                  width="180">
                  <template slot-scope="{row, $index}">
                    <DescriptionsItem v-model="row.payAmount"></DescriptionsItem>
                  </template>
                </el-table-column>
                <el-table-column label="备注"
                  prop="remark">
                  <template slot-scope="{row, $index}">
                    <DescriptionsItem v-model="row.remark"></DescriptionsItem>
                  </template>
                </el-table-column>
                <el-table-column label="支付时间"
                  width="260"
                  prop="payTime">
                  <template slot-scope="{row, $index}">
                    <el-form-item :prop="'payeeList.' + $index + '.payTime'">
                      
                      <!-- :rules="[{ required: true, message: '请选择支付时间' }]" -->
                      <el-date-picker v-model="row.payTime"
                        type="datetime" value-format="yyyy-MM-dd HH:mm:ss"
                        placeholder="选择日期时间"></el-date-picker>
                    </el-form-item>
                  </template>
                </el-table-column>
                <el-table-column label="通知单号"
                  width="220"
                  prop="noticeNo">
                  <template slot-scope="{row, $index}">
                    <el-form-item :prop="'payeeList.' + $index + '.noticeNo'">
                      <el-input placeholder="请输入"
                        v-model="row.noticeNo"></el-input>
                    </el-form-item>
                  </template>
                </el-table-column>
                <el-table-column label="流水号"
                  width="220"
                  prop="tradeCode">
                  <template slot-scope="{row, $index}">
                    <el-form-item :prop="'payeeList.' + $index + '.tradeCode'">
                      <el-input placeholder="请输入"
                        v-model="row.tradeCode"></el-input>
                    </el-form-item>
                  </template>
                </el-table-column>
              </el-table>
            </el-form>
          </div>
        </div>
        <div style="height: 20px"></div>
        <div>
          <div class="title">
            <span class="title-text">结案数据</span>
          </div>
          <div class="item-box">
            <el-row :gutter="10">
              <el-col :xs="24"
                :sm="12"
                :md="12"
                :lg="8"
                :xl="8">
                <div class="detail-text">报案时间：{{ caseData.reportCaseTime }}</div>
              </el-col>
              <el-col :xs="24"
                :sm="12"
                :md="12"
                :lg="8"
                :xl="8">
                <div class="detail-text">结案时间：{{ caseData.closeCaseTime }}</div>
              </el-col>
              <el-col :xs="24"
                :sm="12"
                :md="12"
                :lg="8"
                :xl="8">
                <div class="detail-text">结案时效：{{ caseData.closeDurationTime }}</div>
              </el-col>
              <el-col :xs="24"
                :sm="12"
                :md="12"
                :lg="8"
                :xl="8">
                <div class="detail-text">预估损失金额：{{ caseData.estimateLoss }}</div>
              </el-col>
              <el-col :xs="24"
                :sm="12"
                :md="12"
                :lg="8"
                :xl="8">
                <div class="detail-text">实际赔付金额：{{ caseData.indemnityAmount }}</div>
              </el-col>
              <el-col :xs="24"
                :sm="12"
                :md="12"
                :lg="8"
                :xl="8">
                <div class="detail-text">准确率：{{ caseData.accuracy || 0 }}</div>
              </el-col>
              <el-col :xs="24"
                :sm="12"
                :md="12"
                :lg="8"
                :xl="8">
                <div class="detail-text">客户经理：{{ caseData.caseFollower }}</div>
              </el-col>
              <el-col :xs="24"
                :sm="12"
                :md="12"
                :lg="8"
                :xl="8">
                <div class="detail-text">区域经理：{{ caseData.regionalManager }}</div>
              </el-col>
            </el-row>
          </div>
        </div>
        <div>
          <div class="title">
            <span class="title-text">客户经理备注</span>
          </div>
          <div class="item-box">
            <el-input type="textarea"
              :rows="5"
              placeholder="请输入内容"
              maxlength="150"
              v-model="textarea"></el-input>
          </div>
        </div>
        <div style="height: 20px"></div>
        <div>
          <div class="title">
            <span class="title-text">单据影像资料</span>
          </div>
          <div class="item-box">
            <PicUpload v-model="picList"
              class="pic-upload"></PicUpload>
          </div>
        </div>
      </div>
    </div>
  </el-dialog>
</template>

<script>
import { insClaimsCaseClose } from "@/api/claimData";
import PicUpload from "../PicUpload.vue";
import DescriptionsItem from "../items/DescriptionsItem.vue";
export default {
  props: {
    showDialog: Boolean,
    caseData: {
      type: Object,
      default: () => { }
    }
  },
  components: { PicUpload, DescriptionsItem },
  watch: {
    showDialog: {
      handler(v) {
        this.textarea = '';
        // if (!v) {

        // } else {

        // }
      }
    }
  },
  data() {
    return {
      textarea: '',
      picList: '',
      payeeList: [],
      rulesClaimFrom: {},
      rulesData: {
        payeeList: []
      }
    };
  },
  methods: {
    close() {
      this.$emit('update:showDialog', false);
    },
    submit() {
      this.$refs.claimFrom.validate((valid, validObj) => {
        console.log(valid, validObj);
        if (valid) {
          insClaimsCaseClose({
            "accuracy": this.caseData.accuracy || '0%',
            "caseId": this.caseData.id,
            "closeCaseTime": this.caseData.closeCaseTime,
            "closeDurationTime": this.caseData.closeDurationTime,
            "closeRemark": this.textarea,
            "realCompensationAmount": this.caseData.indemnityAmount,
            "reopenCount": this.caseData.reopenCount,
            claimsPayments: this.rulesData.payeeList,
            attachmentType: 'DJYX',
            attachments: { "DJYX": this.picList }
          }).then(res => {
            console.log(res);
            this.close();
            this.$emit('changeList');
          }).catch(err => {
            console.log(err);
          });
        } else {
          //  this.$message.error('校验未通过');
          let messageList = [];
          // this.$message.error('校验未通过');
          for (const key in validObj) {
            messageList.push(validObj[key][0]);
          }
          if (messageList.length) {
            this.$message.error(messageList[0].message);
            this.$refs[messageList[0].field].$el.scrollIntoView();
          } else {
            reject("校验未通过");
          }
        }
      });
    }
  }
}
</script>

<style lang="scss" scoped>
.suspension-box {
  margin-bottom: 10px;
  border-radius: 8px;
  border: none;
  background-color: #fff;
  overflow: hidden;
}
.type-title {
  height: 60px;
  text-align: center;
  line-height: 60px;
  background: #fca118;
  font-size: 20px;
  font-weight: bold;
  color: #ffffff;
}
.suspension {
  .title {
    display: flex;
    align-items: center;
    font-size: 16px;
    font-weight: bold;
    color: #333333;
    line-height: 0px;
    margin-bottom: 19px;
    .title-text {
      margin: 0 12px 0 8px;
    }
  }
  .title::before {
    content: '';
    display: inline-block;
    width: 6px;
    height: 18px;
    background: #4278c9;
    border-radius: 4px 4px 4px 4px;
    opacity: 1;
  }
  .title::after {
    content: '';
    display: inline-block;
    width: 75%;
    height: 1px;
    background: #ccccccff;
    opacity: 1;
    text-align: right;
  }
  .item-box {
    margin: 0 12px;
    .item-conten {
      display: flex;
      font-size: 14px;
      color: #333333;
      margin-bottom: 24px;
    }
    .item-textarea {
      padding: 8px 12px;
      border-radius: 4px 4px 4px 4px;
      border: 1px solid #cccccc;
    }
    .img-title {
      margin-top: 10px;
    }
  }
  .detail-text {
    font-size: 14px;
    color: #333;
    margin-bottom: 20px;
  }
}
.dialog-title {
  font-size: 18px;
  font-weight: bold;
  color: #ffffff;
  line-height: 0px;
}
::v-deep .el-dialog__header {
  background: #4278c9;
  border-radius: 8px 8px 0px 0px;
  opacity: 1;
  height: 50px;
}
::v-deep .el-dialog {
  border-radius: 8px;
}
</style>