import { render, staticRenderFns } from "./PicUpload.vue?vue&type=template&id=4e811788&scoped=true"
import script from "./PicUpload.vue?vue&type=script&lang=js"
export * from "./PicUpload.vue?vue&type=script&lang=js"
import style0 from "./PicUpload.vue?vue&type=style&index=0&id=4e811788&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4e811788",
  null
  
)

export default component.exports