<template>
  <el-card class="container">
    <div class="mian">
      <el-image
        :src="require('@/assets/images/claim/current-step.png')"
        :style="{ left: (stepIndex * (100 / (stepList.length-1)) > 100 ? 100 : stepIndex * (100 / (stepList.length-1))) +'%' }"
        class="claim-step"
      />

      <!-- 前面完成条加完成圆圈和底部文字 -->
      <div
        class="step-item"
        :class="{ 'step-item2': index == 0 }"
        v-for="(stepitem,index) in stepList"
        :key="index"
      >
        <!-- 长条加圆圈 -->
        <div class="strip-circle">
          <!-- 长条 -->
          <div
            class="strip"
            v-if="actionStep == index"
            :style="{background:'#4278C9FF'}"
          ></div>
          <div
            class="strip"
            v-if="actionStep < index"
            :style="{background:'#EEEEEEFF'}"
          ></div>
          <div
            class="strip"
            v-else-if="actionStep > index"
            :style="{background:'#00bc0d'}"
          ></div>
          <!-- 圆圈 -->
          <div class="circle">
            <el-image
              v-if="actionStep==index"
              :src="require('@/assets/images/claim/c1.png')"
              @click="handleChangeStep(index)"
              style="width: 30px; height: 30px;margin-top: 5px;cursor: pointer;}"
            />
            <el-image
              v-else-if="actionStep<index"
              :src="require('@/assets/images/claim/c2.png')"
              :style="{width:'30px', height: actionStep<index+1? '30px':'40px', marginTop: actionStep<index+1? '5px' : '0'}"
            />
            <el-image
              v-else
              :src="require('@/assets/images/claim/c3.png')"
              @click="handleChangeStep(index)"
              :style="{width:actionStep<index? '30px':'35px', height: actionStep<index+1? '30px':'35px', marginTop: actionStep<index+1? '5px' : '3px',cursor: 'pointer'}"
            />
            <!-- 底部文字 -->
            <div
              :class="{'footer-box': true, 'footer-box-tail': index === (stepList.length-1), 'footer-box-one': index === 0}"
            >
              <div
                class="text"
                :style="{ color: actionStep < index ? '' : '#4278C9', fontWeight: 'bold'}"
              >{{stepitem.title}}</div>
              <div class="subtitle">({{stepitem.subtitle}})</div>
              <div class="completeDate">{{stepitem.completeDate}}</div>
              <div class="updateTime">{{stepitem.updateTime}}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- {{actionStep}}{{index}} -->
  </el-card>
</template>

<script>
import Icon from "@/components/Icon/Iconfont.vue";
export default {
  name: 'ClaimStep',
  components: { Icon },
  props: {
    stepList: {
      type: Array,
      default: () => {
        return [
          { value: 0, text: '1' },
          { value: 1, text: '2' },
          { value: 2, text: '3' },
        ];
      }
    },
    step: {
      type: Number,
      default: 0
    },
    stepIndex: {
      type: Number,
      default: 0
    },
    detail: {
      type: Object,
      default: () => { }
    }
  },
  data() {
    return {
      commitStyle: {
        width: '58px',
        height: '58px'
      },
      baseStyle: {
        width: '40px',
        height: '40px'
      }
    };
  },
  computed: {
    actionStep() {
      if (this.step > this.stepList.length) {
        return this.stepList.length;
      } else if (this.step === 0) {
        return 0;
      }
      return this.step;
    }
  },
  methods: {
    handleChangeStep(ind) {
      if (this.detail.subjectInspectionProcessStatus == 2 && ind > 1) {
        return false;
      } else if (this.detail.subjectLossProcessStatus == 2 && ind > 2) {
        return false;
      } else if (this.detail.adjustmentProcessStatus == 2 && ind > 4) {
        return false;
      } else {
        this.$emit('update:stepIndex', ind);
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.mian {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100px;
}
.container {
  height: 190px;
  padding: 0px 68px 20px;
  margin-bottom: 10px;
  ::v-deep .el-card__body {
    padding: 0;
  }
  .mian {
    position: relative;
    .claim-step {
      position: absolute;
      bottom: calc(50% + 12px);
      transform: translateX(-50%);
      transition: all 0.2s;
      width: 19px;
      height: 16px;
    }
  }
}
.step-item {
  flex: 1;
  display: column;
  .text {
    margin-top: 12px;
    font-size: 18px;
    text-align: center;
    color: #333333;
  }
  .subtitle {
    margin-top: 4px;
    font-size: 14px;
    text-align: center;
    color: #333333;
  }
  .completeDate {
    margin-top: 4px;
    font-size: 12px;
    text-align: center;
    color: #333333;
    font-weight: 300;
  }
  .updateTime {
    margin-top: 4px;
    font-size: 12px;
    text-align: center;
    color: #333333;
    font-weight: 300;
  }
  .strip-circle {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    position: relative;
    .strip {
      height: 12px;
      width: 100%;
      background-color: #eeeeeeff;
    }
    .circle {
      position: absolute;
      top: -14px;
      right: 0;
      transform: translateX(50%);
      width: 160px;
      z-index: 8;
      .footer-box {
        position: absolute;
        width: 200%;
        top: 100%;
        left: 50%;
        transform: translateX(-50%);
      }
      .footer-box-one {
        left: calc(50% - 20px);
        transform: none;
        .text {
          text-align: left;
        }
        .subtitle {
          text-align: left;
        }
        .completeDate {
          text-align: left;
        }
        .updateTime {
          text-align: left;
        }
      }

      .footer-box-tail {
        left: calc(-100% - 45px);
        transform: none;
        .text {
          text-align: right;
        }
        .subtitle {
          text-align: right;
        }
        .completeDate {
          text-align: right;
        }
        .updateTime {
          text-align: right;
        }
      }
    }
  }
}
.step-item2 {
  width: 0;
  flex: 0;
}
</style>
<style lang="scss">
.circle {
  .el-image {
    display: block;
    margin: auto;
  }
}
</style>