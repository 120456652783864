<template>
  <el-dialog destroy-on-close :visible.sync="dialogVisible">
    <template #title>
      <span class="dialog-title">修改历史</span>
    </template>
    <div class="his-main">
      <div v-for="(hisItem, index) in hisList" :key="index" class="his-tb">
        <!-- 头 -->
        <div class="header-text">
          <span class="header-text-left">{{ hisItem.time }}</span>
          <span class="header-text-right"
            >修改人:{{ hisItem.updateUserName }}</span
          >
        </div>
        <!-- 表格 -->
        <div>
          <el-table
            :max-height="200"
            border
            style="width: 100%"
            :data="hisItem.lossItemHistories"
            :header-cell-style="{
              'text-align': 'center',
              background: '#EFF3FAFF',
            }"
            :cell-style="{ 'text-align': 'center' }"
          >
            <el-table-column label="序号" type="index"> </el-table-column>
            <el-table-column prop="lossType" label="定损项种类">
              <template slot-scope="{ row }">
                {{ lossDict[row.lossType] }}
              </template>
            </el-table-column>
            <el-table-column prop="lossItemName" label="定损项名称">
            </el-table-column>
            <el-table-column prop="brand" label="品牌"> </el-table-column>
            <el-table-column prop="guidePrice" label="指导单价">
            </el-table-column>
            <el-table-column prop="actualPrice" label="实际单价">
              <template slot-scope="{ row }">
                {{ row.actualPrice.toFixed(2) }}
              </template>
            </el-table-column>
            <el-table-column prop="count" label="数量"> </el-table-column>
            <el-table-column prop="amount" label="金额">
              <template slot-scope="{ row }">
                {{ row.amount.toFixed(2) }}
              </template>
            </el-table-column>
            <el-table-column prop="existsTax" label="是否含税">
              <template slot-scope="{ row }">
                {{ row.existsTax ? "是" : "否" }}
              </template>
            </el-table-column>
            <el-table-column prop="residualPrice" label="残值金额">
              <template slot-scope="{ row }">
                {{ row.residualPrice.toFixed(2) }}
              </template>
            </el-table-column>
            <el-table-column prop="remark" label="备注"> </el-table-column>
          </el-table>
        </div>
      </div>
    </div>
  </el-dialog>
</template>

<script>
import { claimLossHis } from "@/api/claimData";
export default {
  name: "LossHisDB",
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    id: {
      type: String,
      default: "",
    },
    lossDict: {
      type: Object,
      default: () => {},
    },
  },
  watch: {
    dialogVisible(n) {
      this.$emit("update:show", n);
    },
    show(n) {
      this.dialogVisible = n;
      if (n) {
        claimLossHis({ lossId: this.id }).then((res) => {
          this.hisList = res.data;
        });
      }
    },
  },
  data() {
    return {
      dialogVisible: false,
      hisList: [],
    };
  },
};
</script>

<style lang="scss" scoped>
::v-deep .el-dialog__header {
  background: #4278c9;
  border-radius: 8px 8px 0px 0px;
  opacity: 1;
  height: 50px;
}
::v-deep .el-dialog {
  border-radius: 8px;
  width: 1600px;
}
.dialog-title {
  font-size: 18px;
  font-weight: bold;
  color: #ffffff;
  line-height: 0px;
}
.his-main {
  display: column;
}
.his-tb {
  border-bottom: 1px dashed #cccccc;
  padding-bottom: 24px;
  margin-top: 12px;
  .header-text {
    margin-bottom: 12px;
    .header-text-left {
      margin-right: 12px;
    }
  }
}
</style>
