//正浮点数校验
export function floorNum(rule, value, callback){
    if(value==''){
        callback()
    }else{
        if(!/^(\d+)(\.\d+)?$/.test(value)){
            callback(new Error('请输入正确的数值！'))
        }else{
            callback();
        }
    }
}

//电话号码
export function phoneNum(rule, value, callback){
    if(value==''){
        callback()
    }else{
        if(/^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/.test(value)){
            callback()
        }else{
            callback(new Error('请输入正确手机号码！'));
        }
    }
}

//身份证号码
export function iDNum(rule, value, callback){
    if(value==''){
        callback()
    }else{
        if(/^\d{15}|\d{18}$/.test(value)){
            callback()
        }else{
            callback(new Error('请输入正确身份证号码！'));
        }
    }
}