<template>
  <div>
    <el-form :model="claimFrom"
      ref="claimFrom"
      :rules="rulesClaimFrom"
      inline-message
      label-position="top">
      <AssociationScroll :showTabs="false">
        <AssociationScrollView class="lose"
          title="定损信息"
          name="0">
          <template slot="head-right">
            <el-button @click="showHis = true"
              class="his-btn">修改历史</el-button>
          </template>
          <!-- 定损列表 -->
          <div class="item-table">
            <el-table :max-height="200"
              :data="claimFrom.itemList"
              class="table-main"
              header-row-class-name="table-header"
              border
              style="width: 100%"
              :header-cell-style="{
                'text-align': 'center',
                background: '#EFF3FAFF',
              }"
              :cell-style="{ 'text-align': 'center' }">
              <el-table-column label="序号"
                type="index"></el-table-column>
              <el-table-column label="定损项种类"
                v-if="auth('lossType','e')">
                <template slot-scope="{ row, $index }">
                  <el-form-item v-if="edit"
                    :key="$index"
                    :prop="'itemList.' + $index + '.lossType'"
                    :ref="'itemList.' + $index + '.lossType'"
                    :rules="[{ required: true, message: '不能为空' }]">
                    <el-select :disabled="
                        isReOpen && row.reopenCount != detail.reopenCount
                      "
                      v-model="row.lossType"
                      @change="changeReopenCount($event, row)"
                      placeholder="请选择">
                      <el-option v-for="item in claimItemDict"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value"></el-option>
                    </el-select>
                  </el-form-item>
                  <DescriptionsItem v-else
                    :content="claimItemDictObj[row.lossType]"></DescriptionsItem>
                </template>
              </el-table-column>
              <el-table-column prop="lossItemName"
                v-if="auth('lossItemName','e')"
                label="定损项名称">
                <template slot-scope="{ row, $index }">
                  <el-form-item v-if="edit"
                    :prop="`lossItemName${$index}`">
                    <el-autocomplete :disabled="
                        isReOpen && row.reopenCount != detail.reopenCount
                      "
                      v-model.lazy="row.lossItemName"
                      :fetch-suggestions="
                        (queryString, cb) => {
                          nameFuzzyQuery(queryString, cb, row);
                        }
                      "
                      @select="nameFuzzyQuerySelect($event, row)"
                      :trigger-on-focus="false" />
                  </el-form-item>
                  <DescriptionsItem v-else
                    v-model="row.lossItemName"></DescriptionsItem>
                </template>
              </el-table-column>
              <el-table-column prop="brand"
                v-if="auth('brand','e')"
                label="品牌">
                <template slot="header">
                  品牌
                  <el-tooltip class="item"
                    effect="dark"
                    content="选择代理商配件和代理商人工, 自动带出品牌, 与保单标的品牌相同"
                    placement="top-start">
                    <i class="el-icon-question tips"></i>
                  </el-tooltip>
                </template>
              </el-table-column>
              <el-table-column prop="guidePrice"
                v-if="auth('guidePrice','e')"
                label="指导单价">
                <template slot="header">
                  指导单价
                  <el-tooltip class="item"
                    effect="dark"
                    content="根据定损名称历史填写的实际单价, 自动带出指导单价区间, 区间为历史输入的实际单价最高和历史输入实际单价最低"
                    placement="top-start">
                    <div slot="content">
                      根据定损名称历史填写的实际单价, 自动带<br />出指导单价区间,区间为历史输入的实际
                      <br />单价最高和历史输入实际单价最低
                    </div>
                    <i class="el-icon-question tips"></i>
                  </el-tooltip>
                </template>
              </el-table-column>
              <el-table-column prop="actualPrice"
                v-if="auth('actualPrice','e')"
                label="实际单价">
                <template slot-scope="{ row, $index}">
                  <el-form-item v-if="edit"
                    :key="$index"
                    :prop="'itemList.' + $index + '.actualPrice'"
                    :ref="'itemList.' + $index + '.actualPrice'"
                    :rules="[
                      {
                        validator: validator.floorNum,
                        message: '请输入数字值！',
                        required: true,
                      },
                    ]">
                    <el-input :disabled="
                        isReOpen && row.reopenCount != detail.reopenCount
                      "
                      placeholder="请输入"
                      v-model="row.actualPrice" />
                  </el-form-item>
                  <DescriptionsItem v-else
                    v-model="row.actualPrice"></DescriptionsItem>
                </template>
              </el-table-column>
              <el-table-column prop="count"
                v-if="auth('count','e')"
                label="数量">
                <template slot-scope="{ row, $index }">
                  <el-form-item v-if="edit"
                    :prop="'itemList.' + $index + '.count'"
                    :ref="'itemList.' + $index + '.count'"
                    :rules="[{ type: 'number', message: '请输入数字值！' }]">
                    <el-input :disabled="
                        isReOpen && row.reopenCount != detail.reopenCount
                      "
                      placeholder="请输入"
                      v-model.number="row.count" />
                  </el-form-item>
                  <DescriptionsItem v-else
                    v-model="row.count"></DescriptionsItem>
                </template>
              </el-table-column>
              <el-table-column label="金额">
                <template slot-scope="{ row }">{{
                  row.actualPrice * row.count
                }}</template>
                <template slot="header">
                  金额
                  <el-tooltip class="item"
                    effect="dark"
                    content="实际单价*数量"
                    placement="top-start">
                    <i class="el-icon-question tips"></i>
                  </el-tooltip>
                </template>
              </el-table-column>
              <el-table-column prop="existsTax"
                v-if="auth('existsTax','e')"
                label="是否含税">
                <template slot-scope="{ row }">
                  <el-select v-if="edit"
                    :disabled="
                      isReOpen && row.reopenCount != detail.reopenCount
                    "
                    v-model="row.existsTax"
                    placeholder="请选择">
                    <el-option :key="true"
                      label="是"
                      :value="true"></el-option>
                    <el-option :key="false"
                      label="否"
                      :value="false"></el-option>
                  </el-select>
                  <DescriptionsItem v-else
                    :content="row.existsTax ? '是' : '否'"></DescriptionsItem>
                </template>
              </el-table-column>
              <el-table-column prop="residualPrice"
                v-if="auth('residualPrice','e')"
                label="残值金额">
                <template slot-scope="{ row, $index }">
                  <el-form-item v-if="edit"
                    :key="$index"
                    :prop="'itemList.' + $index + '.residualPrice'"
                    :ref="'itemList.' + $index + '.residualPrice'"
                    :rules="[
                      {
                        validator: validator.floorNum,
                        message: '请输入数字值！',
                      },
                    ]">
                    <el-input :disabled="
                        isReOpen && row.reopenCount != detail.reopenCount
                      "
                      placeholder="请输入"
                      v-model="row.residualPrice" />
                  </el-form-item>
                  <DescriptionsItem v-else
                    v-model="row.residualPrice"></DescriptionsItem>
                </template>
              </el-table-column>
              <el-table-column prop="remark"
                v-if="auth('remark','e')"
                label="备注">
                <template slot-scope="{ row }">
                  <el-input v-if="edit"
                    :disabled="
                      isReOpen && row.reopenCount != detail.reopenCount
                    "
                    placeholder="请输入"
                    v-model="row.remark" />
                  <DescriptionsItem v-else
                    v-model="row.remark"></DescriptionsItem>
                </template>
              </el-table-column>
              <el-table-column label="操作">
                <template slot-scope="{ row, $index }">
                  <div>
                    <el-button v-if="
                        ($index && edit && !isReOpen) ||
                        (row.reopenCount == detail.reopenCount &&
                          edit &&
                          $index)
                      "
                      @click="lossItemDelet($index)"
                      class="tb-delet-btn"
                      icon="el-icon-circle-close"
                      type="text">删除</el-button>
                    <div v-else>--</div>
                  </div>
                </template>
              </el-table-column>
            </el-table>
            <div class="add-item"
              v-if="edit">
              <el-button class="item-add-btn"
                icon="el-icon-circle-plus-outline"
                type="text"
                @click="addLossItem">添加</el-button>
            </div>
          </div>
          <!-- 定损项合计 -->
          <div class="total-table">
            <div class="title-text">定损项种类合计金额</div>
            <el-table show-summary
              :max-height="200"
              border
              style="width: 100%"
              :data="summationTable"
              :row-class-name="tableRowClassName"
              :header-cell-style="{
                'text-align': 'center',
                background: '#EFF3FAFF',
              }"
              :cell-style="{ 'text-align': 'center' }">
              <el-table-column label="序号"
                v-if="auth('lossType','e')"
                type="index"
                width="80"></el-table-column>
              <el-table-column prop="lossTypeName"
                v-if="auth('lossType','e')"
                label="定损项种类"></el-table-column>
              <el-table-column prop="totalAmount"
                v-if="auth('lossType','e')"
                label="合计金额"></el-table-column>
            </el-table>
            <!-- <div class="total-all">总金额：5000.00 元</div> -->
          </div>
          <!-- 各类比例输入 -->
          <div class="ratio-info">
            <el-row :gutter="160">
              <el-col :xs="7"
                :sm="7"
                :md="7"
                :lg="7"
                :xl="7">
                <el-form-item label="投保比例"
                  v-if="auth('insureRate','e')"
                  required
                  prop="insureRate"
                  ref="insureRate">
                  <el-input :disabled="isReOpen"
                    v-model="claimFrom.insureRate"
                    placeholder="请输入"
                    v-if="edit">
                    <div slot="append">%</div>
                  </el-input>
                  <DescriptionsItem v-else
                    v-model="claimFrom.insureRate"
                    suffix="%"></DescriptionsItem>
                </el-form-item>
              </el-col>
              <el-col :xs="7"
                :sm="7"
                :md="7"
                :lg="7"
                :xl="7">
                <el-form-item label="责任比例"
                  v-if="auth('caseLiabilityRate','e')"
                  prop="caseLiabilityRate"
                  ref="caseLiabilityRate">
                  <el-input v-model="claimFrom.caseLiabilityRate"
                    placeholder="请输入"
                    v-if="edit">
                    <div slot="append">%</div>
                  </el-input>
                  <DescriptionsItem v-else
                    v-model="claimFrom.caseLiabilityRate"
                    suffix="%"></DescriptionsItem>
                </el-form-item>
              </el-col>
              <el-col :xs="7"
                :sm="7"
                :md="7"
                :lg="7"
                :xl="7">
                <el-form-item label="免赔合计"
                  v-if="auth('totalDeductible','e')"
                  required
                  prop="totalDeductible"
                  ref="totalDeductible">
                  <el-input :disabled="isReOpen"
                    v-model="claimFrom.totalDeductible"
                    placeholder="请输入"
                    v-if="edit">
                    <el-select :disabled="isReOpen"
                      class="select"
                      v-model="claimFrom.totalDeductibleUnit"
                      slot="append">
                      <el-option label="元"
                        :value="1"></el-option>
                      <el-option label="%"
                        :value="2"></el-option>
                    </el-select>
                  </el-input>
                  <DescriptionsItem v-else
                    v-model="claimFrom.totalDeductible"
                    :suffix="claimFrom.totalDeductibleUnit == 1 ? '元' : '%'"></DescriptionsItem>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row :gutter="160">
              <el-col :xs="7"
                :sm="7"
                :md="7"
                :lg="7"
                :xl="7">
                <el-form-item label="是否购买不计免赔"
                  v-if="auth('buyDeductible','e')">
                  <el-radio-group v-if="edit"
                    :disabled="isReOpen"
                    v-model="claimFrom.buyDeductible">
                    <el-radio :label="true">是</el-radio>
                    <el-radio :label="false">否</el-radio>
                  </el-radio-group>
                  <DescriptionsItem v-else
                    :content="claimFrom.buyDeductible?'是':'否'"></DescriptionsItem>
                </el-form-item>
              </el-col>
              <el-col :xs="7"
                :sm="7"
                :md="7"
                :lg="7"
                :xl="7">
                <el-form-item label="残值合计"
                  v-if="auth('residualPrice','e')">
                  <el-input v-model="residualPriceTotal"
                    placeholder="请输入"
                    v-if="edit"
                    disabled></el-input>
                  <DescriptionsItem v-else
                    v-model="residualPriceTotal"></DescriptionsItem>
                </el-form-item>
              </el-col>
              <el-col :xs="7"
                :sm="7"
                :md="7"
                :lg="7"
                :xl="7">
                <el-form-item label="应赔金额">
                  <el-input v-if="edit"
                    v-model="amountPayable"
                    placeholder="请输入"
                    disabled></el-input>
                  <DescriptionsItem v-else
                    v-model="amountPayable"></DescriptionsItem>
                </el-form-item>
              </el-col>

            </el-row>
            <el-row :gutter="160">
              <el-col :xs="7"
                :sm="7"
                :md="7"
                :lg="7"
                :xl="7">
                <el-form-item label="增值税合计"
                  v-if="auth('valueAdded','e')"
                  prop="valueAdded"
                  ref="valueAdded">
                  <el-input :disabled="isReOpen"
                    placeholder="请输入"
                    v-if="edit"
                    v-model="claimFrom.valueAdded"></el-input>
                  <DescriptionsItem v-else
                    v-model="claimFrom.valueAdded"></DescriptionsItem>
                </el-form-item>
              </el-col>
              <el-col :xs="7"
                :sm="7"
                :md="7"
                :lg="7"
                :xl="7">
                <el-form-item label="公估合计"
                  v-if="auth('appraisalFee','e')"
                  prop="appraisalFee">
                  <el-input v-model="claimFrom.appraisalFee"
                    maxlength="10"
                    placeholder="请输入"
                    v-if="edit"></el-input>
                  <DescriptionsItem v-else
                    v-model="claimFrom.appraisalFee"></DescriptionsItem>
                </el-form-item>
              </el-col>
            </el-row>
          </div>
          <!-- 外修厂信息 -->
          <div class="fix-info-main">
            <el-row :gutter="160"
              class="fix-info"
              v-for="(fac, index) in claimFrom.factoryList"
              :key="index">
              <div>
                <el-col :xs="7"
                  :sm="7"
                  :md="7"
                  :lg="7"
                  :xl="7">
                  <el-form-item label="外修厂名称"
                    v-if="auth('outerFactoryName','e')">
                    <el-input :disabled="isReOpen"
                      v-model="fac.outerFactoryName"
                      placeholder="请输入"
                      v-if="edit"></el-input>
                    <DescriptionsItem v-else
                      v-model="fac.outerFactoryName"></DescriptionsItem>
                  </el-form-item>
                </el-col>
                <el-col :xs="7"
                  :sm="7"
                  :md="7"
                  :lg="7"
                  :xl="7">
                  <el-form-item label="是否合作外修厂"
                    v-if="auth('genuineJoin','e')">
                    <el-select :disabled="isReOpen"
                      v-model="fac.genuineJoin"
                      v-if="edit"
                      style="width: 100%">
                      <el-option label="是"
                        :value="true"></el-option>
                      <el-option label="否"
                        :value="false"></el-option>
                    </el-select>
                    <DescriptionsItem v-else
                      :content="fac.genuineJoin ? '是' : '否'"></DescriptionsItem>
                  </el-form-item>
                </el-col>
                <el-col :xs="7"
                  :sm="7"
                  :md="7"
                  :lg="7"
                  :xl="7">
                  <el-form-item label="外修厂联系电话"
                    v-if="auth('outerFactoryConcat','e')"
                    :prop="'factoryList.' + index + '.outerFactoryConcat'"
                    :ref="'factoryList.' + index + '.outerFactoryConcat'"
                    :rules="[
                      {
                        validator: validator.phoneNum,
                        message: '请输入正确电话号码！',
                      },
                    ]">
                    <el-input :disabled="isReOpen"
                      v-model.number="fac.outerFactoryConcat"
                      placeholder="请输入"
                      v-if="edit"></el-input>
                    <DescriptionsItem v-else
                      v-model="fac.outerFactoryConcat"></DescriptionsItem>
                  </el-form-item>
                </el-col>
                <el-col v-if="edit"
                  :pull="1"
                  :xs="3"
                  :sm="3"
                  :md="3"
                  :lg="3"
                  :xl="3">
                  <el-form-item>
                    <div>
                      <el-button v-if="index"
                        @click="deletOutFac(index)"
                        class="delet-btn"
                        icon="el-icon-circle-close">删除</el-button>
                    </div>
                  </el-form-item>
                </el-col>
              </div>
            </el-row>
            <div v-if="edit&&auth('outerFactoryName','e')">
              <el-button @click="addOutFac"
                class="add-btn"
                type="text">添加外修厂</el-button>
            </div>
          </div>
          <!-- 备注 -->
          <div class="remark"
            v-if="auth('outerRemark','e')">
            <el-form-item required
              prop="outerRemark"
              ref="outerRemark">
              <template #label>
                <span class="label">备注</span>
              </template>
              <el-input :disabled="!edit || isReOpen"
                v-model="claimFrom.outerRemark"
                type="textarea"
                :rows="4"
                placeholder="请输入备注"></el-input>
            </el-form-item>
          </div>
        </AssociationScrollView>
        <AssociationScrollView title="单据影像资料"
          name="1"
          v-if="auth('attachmentList','e')">
          <PicUpload v-model="claimFrom.attachmentList"
            :disabled="!edit || isReOpen" :limitSize="25"
            :init="1"
            class="pic-upload"></PicUpload>
        </AssociationScrollView>
        <AssociationScrollView title="定损照片"
          name="2"
          v-if="auth('attachmentDingSunList','e')">
          <PicUpload v-model="claimFrom.attachmentDingSunList"
            :disabled="!edit || isReOpen"
            :init="1"
            class="pic-upload"></PicUpload>
        </AssociationScrollView>
        <AssociationScrollView title="复勘照片"
          name="3"
          v-if="auth('attachmentFuKanList','e')">
          <PicUpload v-model="claimFrom.attachmentFuKanList"
            :disabled="!edit || isReOpen"
            :init="1"
            class="pic-upload"></PicUpload>
        </AssociationScrollView>
        <AssociationScrollView class="approval"
          title="区域经理审核"
          name="2"
          v-if="auth('regionalManager','e')">
          <el-switch :disabled="!edit || disabledSwitchAudit || isReOpen"
            class="switch"
            v-model="claimFrom.audit"
            inactive-text="是否提交区域经理审核"></el-switch>
          <div v-if="claimFrom.audit">
            <auditProcess :auditList="auditList" :negotiationList="negotiationList"></auditProcess>
          </div>
        </AssociationScrollView>
      </AssociationScroll>
    </el-form>
    <LossHisDB :show.sync="showHis"
      :lossDict="claimItemDictObj"
      :id="claimFrom.lossId"></LossHisDB>
  </div>
</template>

<script>
import dayjs from "dayjs";
import _ from "lodash";
import { dictionaryBatch } from "@/api/policy";
import { floorNum, phoneNum } from "../../verify";
import {
  claimsLossNameFuzzyQuery,
  fuzzyQueryGetData,
  claimLossEditOrAdd,
  claimLossDetail,
  getAuditRecord,
  pageNegotiation
} from "@/api/claimData";
import AssociationScroll from "@/components/AssociationScroll/AssociationScroll.vue";
import AssociationScrollView from "@/components/AssociationScroll/AssociationScrollView.vue";
import PicUpload from "../PicUpload.vue";
import DescriptionsItem from "../items/DescriptionsItem.vue";
import LossHisDB from "../dialogBox/LossHisDB.vue";
import auditProcess from "../auditProcess.vue";
import { hasPerms, dataPermissions } from "@/utils/auth";
export default {
  name: "LiquidatedDamages",
  components: {
    AssociationScroll,
    AssociationScrollView,
    PicUpload,
    DescriptionsItem,
    LossHisDB,
    auditProcess,
  },
  props: {
    caseId: {
      type: [String, Number],
      default: "",
    },
    edit: {
      type: Boolean,
      default: false,
    },
    id: {
      type: [String, Number],
      default: "",
    },
    detail: {
      type: Object,
      default: () => { },
    },
    processStatus: {
      type: Object,
      default: () => { },
    },
    isReOpen: {
      type: Boolean,
      default: false,
    },
    auditData: {
      type: Object,
      default: () => { },
    },
  },
  data() {
    return {
      validator: {
        floorNum,
        phoneNum,
      },
      //特殊字典值(代理商零配件,代理商人工费)
      specialDict: [2, 1],
      showHis: false,
      // 残值合计
      residualPriceTotal: 0,
      //定损合计
      lossTotalAll: 0,
      oldFrom: {},
      rulesClaimFrom: {
        outerRemark: [
          {
            required: true,
            message: "请输入备注",
            trigger: ["change", "blur"],
          },
        ],
        insureRate: [
          { validator: floorNum, message: "请输入正确数字值！", trigger: ["change", "blur"] },
          { required: true, message: "请输入!", trigger: ["change", "blur"] },
        ],
        caseLiabilityRate: [
          { validator: floorNum, message: "请输入正确数字值！", trigger: ["change", "blur"] },
        ],
        totalDeductible: [
          { validator: floorNum, message: "请输入正确数字值！", trigger: ["change", "blur"] },
          { required: true, message: "请输入!", trigger: ["change", "blur"] },
        ],
        valueAdded: [{ validator: floorNum, message: "请输入正确数字值！", trigger: ["change", "blur"] }],
        appraisalFee: [{ validator: floorNum, message: "请输入正确数字值！", trigger: ["change", "blur"] }],
      },
      claimFrom: {
        caseId: "",
        lossId: "",
        insureRate: "",
        caseLiabilityRate: "",
        totalDeductible: "",
        totalDeductibleUnit: 1,
        amountPayable: 0,
        outerRemark: "",
        audit: false,
        createDeptId: "",
        tenantId: "",
        valueAdded: 0,
        appraisalFee: 0,
        itemList: [
          {
            createUser: "",
            createTime: "",
            updateUser: "",
            updateTime: "",
            isDeleted: 0,
            id: "",
            caseId: "",
            lossId: "",
            lossType: "",
            lossItemName: "",
            brand: "",
            guidePriceStr: "",
            guidePrice: "",
            actualPrice: 0,
            count: 0,
            amount: 0,
            existsTax: false,
            residualPrice: 0,
            remark: "",
          },
        ],
        attachmentList: [],
        attachmentDingSunList: [],
        attachmentFuKanList: [],
        factoryList: [
          {
            outerFactoryName: "",
            genuineJoin: false,
            outerFactoryConcat: "",
          },
        ],
        buyDeductible: ''
      },
      claimItemDictObj: {},
      claimItemDict: [],
      auditList: [],
      negotiationList: []
    };
  },
  created() {
    this.initData();
  },
  computed: {
    //合计表格
    summationTable() {
      let totalObj = {};
      this.claimFrom.itemList.forEach((item) => {
        if (item.lossType == "") {
          return;
        }
        if (totalObj[item.lossType]) {
          totalObj[item.lossType].totalAmount +=
            item.count * parseFloat(item.actualPrice || 0);
          totalObj[item.lossType].residualPriceTotal += parseFloat(
            item.residualPrice || 0
          );
        } else {
          totalObj[item.lossType] = {
            lossType: item.lossType,
            lossTypeName: this.claimItemDictObj[item.lossType],
            totalAmount: item.count * parseFloat(item.actualPrice || 0),
            residualPriceTotal: parseFloat(item.residualPrice || 0),
          };
        }
      });
      let totalArray = [];
      for (let i in totalObj) {
        totalArray.push(totalObj[i]);
      }
      //总金额
      let totalAll = 0;
      // 残值合计
      this.residualPriceTotal = 0;
      totalArray.forEach((item) => {
        totalAll += parseFloat(item.totalAmount);
        this.residualPriceTotal += parseFloat(item.residualPriceTotal);
      });
      this.lossTotalAll = totalAll;
      // totalArray.push({
      //   lossType: -1,
      //   lossTypeName: "总金额",
      //   totalAmount: totalAll,
      // });
      return totalArray;
    },
    disabledSwitchAudit() {
      if (this.claimFrom.lossId) {
        if (this.oldFrom.audit) {
          return true;
        } else {
          return false;
        }
      } else {
        return false;
      }
    },
    //应赔金额
    amountPayable() {
      if (this.claimFrom.totalDeductibleUnit == 1) {
        return (
          (this.lossTotalAll - this.residualPriceTotal) *
          (this.claimFrom.insureRate / 100 || 0) *
          (this.claimFrom.caseLiabilityRate / 100 || 0) -
          (this.claimFrom.totalDeductible || 0)
        ).toFixed(2);
      } else if (this.claimFrom.totalDeductibleUnit == 2) {
        return (
          (this.lossTotalAll - this.residualPriceTotal) *
          (this.claimFrom.insureRate / 100 || 0) *
          (this.claimFrom.caseLiabilityRate / 100 || 0) *
          (1 - (this.claimFrom.totalDeductible || 0) / 100)
        ).toFixed(2);
      } else {
        return 0;
      }
    },
  },
  watch: {
    auditData() {
      this.initData();
    },
    edit(n) {
      if (!n) {
        this.$refs.claimFrom.clearValidate();
      }
    },
  },
  methods: {
    async initData() {
      let dictResult = await dictionaryBatch({ codes: "claimItemType" });
      this.claimItemDict = dictResult.data.claimItemType.map((item) => {
        this.$set(this.claimItemDictObj, item.dictKey, item.dictValue);
        return {
          value: item.dictKey,
          label: item.dictValue,
        };
      });
      let claimResult = await claimLossDetail({ caseId: this.id });
      if (Object.keys(claimResult.data).length) {
        this.oldFrom = _.cloneDeep(claimResult.data);
        this.claimFrom = claimResult.data;
      } else {
        this.claimFrom.caseId = this.id;
        this.claimFrom.caseLiabilityRate = this.detail.dutyRate;
        this.oldFrom = Object.assign({}, this.claimFrom);
      }
      if (Object.keys(this.auditData).length) {
        this.auditList = this.auditData.nodes.map((node) => {
          return {
            name: node.approverName,
            type: node.nodeStatusDesc,
            time: dayjs(node.approveTime).format("YYYY-MM-DD HH:mm:ss"),
            comment: node.nodeOpinion,
          };
        });
      } else {
        this.auditList = [
          {
            // name: this.detail.caseFollower,
            name: this.$store.state.userInfo.name,
            type: "发起审核",
            time: dayjs().format("YYYY-MM-DD HH:mm:ss"),
            comment: "",
          },
          //  {
          //    name: this.detail.regionalManager,
          //    type: this.processStatus[this.detail.subjectLossProcessStatus],
          //    time: "",
          //    comment: "",
          //  },
        ];
      }
      pageNegotiation({
        approvalType: 1,
        caseId: this.id,
        size: 999,
      //   processId: this.auditData.processId,
        current: 1,
      }).then(negotiation=>{
         if (negotiation.data && negotiation.data.records) {
            this.negotiationList = negotiation.data.records;
         }
      })
    },
    lossItemDelet(index) {
      this.claimFrom.itemList.splice(index, 1);
    },
    deletOutFac(index) {
      this.claimFrom.factoryList.splice(index, 1);
    },
    tableRowClassName(row) {
      if (row.lossType == -1) {
        return "total-table-row";
      }
      return "";
    },
    addLossItem() {
      let item = {
        lossType: "",
        lossItemName: "",
        brand: "",
        guidePriceStr: "",
        guidePrice: "",
        actualPrice: 0,
        count: 0,
        amount: 0,
        existsTax: true,
        residualPrice: 0,
        remark: "",
        reopenCount: this.isReOpen ? this.detail.reopenCount : 0,
      };
      // if (this.isReOpen) {
      //   item.reopenCount = this.detail.reopenCount;
      // }
      this.claimFrom.itemList.push(item);
    },
    addOutFac() {
      this.claimFrom.factoryList.push({
        outerFactoryName: "",
        genuineJoin: false,
        outerFactoryConcat: "",
      });
    },
    nameFuzzyQuery(queryString, cb, row) {
      claimsLossNameFuzzyQuery({
        itemName: queryString,
        lossId: this.claimFrom.lossId,
        caseId: this.id,
      }).then(
        (res) => {
          if (!res.data.length) {
            row.guidePrice = "";
            cb();
            return;
          }
          let nameList = res.data.map((item) => {
            return {
              value: item.lossItemName,
              ...item,
            };
          });
          cb(nameList);
        },
        (err) => {
          cb();
        }
      );
    },
    async nameFuzzyQuerySelect(e, row) {
      let res = await fuzzyQueryGetData({
        itemName: e.value,
        lossId: this.claimFrom.lossId,
        caseId: this.id,
      });
      row.guidePrice = res.data.guidePrice;
    },
    handleReset() {
      this.claimFrom = _.cloneDeep(this.oldFrom);
    },
    handleSave() {
      this.$refs.claimFrom.validate((valid, validObj) => {
        if (valid) {
          claimLossEditOrAdd(this.claimFrom).then(
            (res) => {
              this.$emit("Submit");
              this.$message.success("编辑成功");
            },
            (err) => { }
          );
        } else {
          let messageList = [];
          // this.$message.error('校验未通过');
          for (const key in validObj) {
            messageList.push(validObj[key][0]);
          }
          if (messageList.length) {
            this.$message.error(messageList[0].message);
            this.$refs[messageList[0].field].$el.scrollIntoView();
          } else {
            reject("校验未通过");
          }
        }
      });
      return true;
    },
    handleWithdraw() {
      return {
        audit: this.claimFrom.audit,
        processId: this.auditData.processId,
      };
    },
    changeReopenCount(e, row) {
      if (this.specialDict.includes(e)) {
        row.brand = this.detail.brand;
      } else {
        row.brand = "";
      }
    },
    auth(v, a) {
      if (a == 'e') {
        return dataPermissions('M21-Edit', v);
      } else if (a == 'v') {
        return dataPermissions('M21-View', v);
      } else if (a == 'a') {
        return dataPermissions('M21-Add', v);
      } else {
        return false;
      }
    }
  },
};
</script>

<style lang="scss" scoped>
::v-deep .cell {
  text-align: center;
}
.tips {
  position: absolute;
  font-size: 12px;
}
::v-deep .el-switch__label {
  font-size: 14px;
  font-weight: 400;
  color: #333333;
}
.total-table-row {
  color: #4278c9ff;
}
.select {
  width: 70px;
}
.table-header {
  background: #4278c9ff;
}
.pic-upload {
  margin-top: 24px;
}
.inactive-icon-class {
  font-size: 14px;
}
.lose {
  .his-btn {
    font-size: 14px;
    font-weight: 400;
    color: #4278c9;
  }
  .item-table {
    .table-main {
      margin-top: 24px;
      .tb-delet-btn {
        color: #c94242;
      }
    }
    .add-item {
      border: 1px solid #dedede;
      display: flex;
      justify-content: center;
      align-items: center;
      height: 32px;
      .item-add-btn {
        color: #00bc0dff;
        font-size: 14px;
      }
      .el-icon-circle-plus-outline {
        margin-right: 6px;
      }
    }
  }
  .total-table {
    margin-top: 24px;
    .title-text {
      font-size: 14px;
      font-weight: bold;
      margin-bottom: 12px;
      color: #333333;
    }
    .total-all {
      text-align: right;
      font-size: 14px;
      font-weight: bold;
      color: #333333;
      margin-top: 12px;
    }
  }
  .ratio-info {
    margin-left: 12px;
    margin-top: 24px;
  }
  .fix-info-main {
    .fix-info {
      background: #f7fafdff;
      border-radius: 8px;
      opacity: 1;
      margin-top: 12px;
      padding: 12px;
      .delet-btn {
        border-radius: 4px 4px 4px 4px;
        opacity: 1;
        border: 1px solid #c94242;
        color: #c94242;
      }
    }
    .add-btn {
      font-weight: bold;
      color: #00bc0d;
      font-size: 14px;
    }
  }
  .remark {
  }
}
.approval {
  .switch {
    margin-top: 24px;
  }
}
</style>
